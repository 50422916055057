import React from "react";
import { Form, withFormik } from "formik";
import * as yup from "yup";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import axios from "axios";
import { api } from "../../../config";
import { mediaUp } from "../../styles/mediaQuery";

import {
  Button,
  Wrapper,
  Typography,
  InputField,
  FieldErrorMsg,
  FullPageLoader,
  AsyncSelect
} from "../../components";

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 12px;
  ${mediaUp.md`
    flex-direction: row; 
      padding-top: 12px;
  padding-bottom: 12px;
  `}
`;

const ItemText = styled(Typography)`
  align-self: flex-start;
  white-space: nowrap;
  font-weight: 500;
  ${mediaUp.md`
     flex-basis: 30%;
  align-self: ${({ error }) => (error ? "center" : "flex-end")};
  `}
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AddProduct = ({
  values,
  errors,
  touched,
  handleChange,
  loading,
  isPageLoading,
  setFieldValue,
  dirty
}) => {
  const loadOptions = async (value) => {
    const res = await axios.get(`${api}/tags?_q=${value}`);
    const tags = res.data.map((tag) => ({ value: tag.id, label: tag.name }));
    return tags;
  };

  return (
    <Wrapper style={{ width: "70%" }}>
      <FullPageLoader isLoading={isPageLoading} />

      <Form>
        <Grid container>
          <Grid item xs={12} py={4} px={0}>
            <ListItem>
              <ItemText component="p" align="left" error={errors?.title}>
                Title
              </ItemText>
              <InputWrapper>
                <InputField
                  fullWidth
                  label="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                />
                <FieldErrorMsg error={errors.title} isTouched={touched.title} />
              </InputWrapper>
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <ListItem>
              <ItemText component="p" align="left" error={errors?.price}>
                Price
              </ItemText>
              <InputWrapper>
                <InputField
                  fullWidth
                  type="number"
                  label="Price"
                  name="price"
                  value={values.price}
                  onChange={handleChange}
                />
                <FieldErrorMsg error={errors.price} isTouched={touched.price} />
              </InputWrapper>
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <ListItem>
              <ItemText component="p" align="left" error={true}>
                Description
              </ItemText>
              <InputWrapper>
                <InputField
                  fullWidth
                  onChange={handleChange}
                  name="description"
                  value={values.description}
                  placeholder="Enter description here..."
                  multiline
                  variant="outlined"
                  color="secondary"
                  rows="4"
                  cols="10"
                />
                <FieldErrorMsg
                  error={errors.description}
                  isTouched={touched.description}
                />
              </InputWrapper>
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <ListItem>
              <ItemText component="p" align="left">
                Tags
              </ItemText>
              <InputWrapper>
                <AsyncSelect
                  id="tags"
                  name="tags"
                  isMulti
                  errors={errors}
                  touched={touched}
                  defaultOptions
                  onChange={setFieldValue}
                  value={values.tags}
                  loadOptions={loadOptions}
                />
              </InputWrapper>
            </ListItem>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Wrapper textAlign="right" width="100%" mt={2}>
            <Button
              variant="contained"
              justify="center"
              mt={2}
              type="submit"
              color="primary"
              fontSize="18"
              fontWeight="500"
              disabled={!dirty}
            >
              Next
            </Button>
          </Wrapper>
        </Grid>
      </Form>
    </Wrapper>
  );
};

const AddProductForm = withFormik({
  mapPropsToValues: ({ product = {} }) => ({
    title: product.title || "",
    price: product.price || "",
    description: product.description || "",
    tags:
      (product.tags &&
        product.tags.map((tag) => ({ label: tag.name, value: tag.id }))) ||
      []
  }),
  validationSchema: yup.object().shape({
    title: yup.string().required("please select type"),
    price: yup.number().required("price is required"),
    description: yup
      .string()
      .min(10, "Enter at least 10 character")
      .max(2000, "Long description is too long (maximum is 2000 characters).")
      .required("please enter long description")
  }),
  handleSubmit(values, { props: { handleAddDetails, handleReset } }) {
    const pr = Number(values.price);
    const payload = {
      ...values,
      price: pr,
      tags: values?.tags?.length ? values?.tags?.map((t) => t.value) : []
    };
    handleAddDetails(payload);
    handleReset({ values: "" });
  }
})(AddProduct);

export default AddProductForm;
