import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { tagActions } from "../../../state/tag";
import MaterialTable, { MTableToolbar } from "material-table";
import { notificationActions } from "../../../state/notification";
import {
  Typography,
  TablePagination,
  Dialog,
  FullPageLoader
} from "../../components";
import { columns } from "./columns";
import CustomToolbar from "./Toolbar";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogContent from "./DialogContent";
import DialogActions from "./DialogActions";

import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="down" ref={ref} mountOnEnter unmountOnExit {...props} />
  );
});

const Layout = styled.div`
  display: "flex";
  flex-direction: column;
`;

const ProductHeader = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const List = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  // const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const tableRef = useRef();
  // const colHistory = localStorage.getItem("Columns");
  // if (colHistory) {
  //   columns.forEach((col, i) => {
  //     if (col.hidden !== colHistory[i].hidden) {
  //       col.hidden = colHistory[i].hidden;
  //     }
  //   });
  // }

  const { getTags, getTagsCount, deleteTag } = tagActions;
  const { showNotification } = notificationActions;

  const deleteHandler = async () => {
    handleClose();
    setLoading(true);
    const res = await dispatch(deleteTag(id));
    if (res?.id) {
      tableRef.current.onQueryChange();
      setLoading(false);
      dispatch(showNotification("Tag deleted successfully", "success"));
    } else {
      setLoading(false);
      dispatch(showNotification("Failed to delete tag", "error"));
    }
  };

  const getData = async (query) => {
    // console.log(query);
    // let filter = {};
    // if (query.filter) {
    //   filter = {
    //     ...query.filter
    //   };
    // }
    const count = await dispatch(getTagsCount());

    const start = query?.page === 0 ? 0 : query.page * query.pageSize;

    const res = await dispatch(
      getTags({
        _q: query?.search,
        _start: start,
        _sort: "updated_at:desc",
        _limit: query.pageSize
      })
    );

    return {
      data: res,
      totalCount: count,
      page: query.page
    };
  };

  return (
    <Layout>
      <FullPageLoader isLoading={loading} />
      <Dialog
        transitionComponent={Transition}
        duration={300}
        open={show}
        heading="Please Confirm"
        handleClose={handleClose}
        scroll="body"
        maxWidth="xs"
        Content={() => <DialogContent />}
        ActionsContent={() => (
          <DialogActions
            deleteHandler={deleteHandler}
            handleClose={handleClose}
          />
        )}
      />
      <ProductHeader>
        <Typography
          style={{
            textTransform: "uppercase",
            fontSize: "1.5rem",
            fontWeight: "bold",
            columnsButton: true,
            export: true
          }}
        >
          Tag list
        </Typography>
      </ProductHeader>
      <MaterialTable
        data={getData}
        tableRef={tableRef}
        onRowClick={(event, rowData) => null}
        options={{
          search: true,
          paginationType: "stepped",
          showTitle: false,
          pageSize: 25
        }}
        actions={[
          {
            icon: () => <DeleteIcon style={{ color: "red" }} />,
            tooltip: "Delete item",
            onClick: (event, rowData) => {
              // console.log(rowData);
              setId(rowData?.id);
              handleShow();
            }
          }
        ]}
        components={{
          Toolbar: (props) => {
            return (
              <Toolbar>
                <MTableToolbar {...props} />
                <CustomToolbar
                  columns={columns}
                  tableRef={tableRef}
                  hideColumn={props.onColumnsChanged}
                  {...props}
                />
              </Toolbar>
            );
          },
          Pagination: (subProps) => {
            return <TablePagination {...subProps} />;
          }
        }}
        columns={columns}
      />
    </Layout>
  );
};

export default List;
