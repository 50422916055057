import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled, { css } from "styled-components";
import { mediaUp } from "../styles/mediaQuery";
import HomeIcon from "@material-ui/icons/Home";
import ExtensionIcon from "@material-ui/icons/Extension";
import AddIcon from "@material-ui/icons/Add";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Link, Typography } from "../components";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

import {
  IconButton,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Drawer
} from "@material-ui/core";
import { Menu, ChevronLeft } from "@material-ui/icons";

import SidebarMenu from "./SidebarMenu";

const useStyles = makeStyles(() => ({
  drawerPaper: {
    "& .MuiDrawer-paper": {
      top: 65
    }
  }
}));

export const AppDrawer = styled(
  ({ drawerOpenWidth, drawerCloseWidth, ...rest }) => <Drawer {...rest} />
)`
  && {
    flex-shrink: 0;
    white-space: nowrap;

    ${(props) =>
      props.open
        ? css`
            transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
            width: ${(props) => `${props.drawerOpenWidth}px`};
          `
        : css`
            overflow-x: hidden;
            transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
            width: ${(props) => `${props.drawerCloseWidth - 16}px`};
          `}

    .MuiDrawer-paperAnchorDockedLeft {
      background-color: #18202e;
      overflow-x: hidden;

      ${(props) =>
        props.open
          ? css`
              transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
              width: ${(props) => `${props.drawerOpenWidth}px`};
            `
          : css`
              transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
              width: ${(props) => `${props.drawerCloseWidth - 16}px`};
            `}
    }

    ${mediaUp.md`
    ${(props) =>
      !props.open &&
      css`
        width: ${(props) => `${props.drawerCloseWidth}px`};
      `}
    .MuiDrawer-paperAnchorDockedLeft {
      ${(props) =>
        !props.open &&
        css`
          width: ${(props) => `${props.drawerCloseWidth}px`};
        `}
    }
    `}
  }

  .MuiListItem-root {
    padding: 0;
    padding-left: 16px;
  }
`;

export const DrawerToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  ${mediaUp.md`
      min-height: 64px;
    `}

  button {
    margin-left: 0;
  }
`;

const AppName = styled(ListItem)`
  flex-direction: column;
  font-size: 20px;
  line-height: 23px;
  padding: 10px;
  & hr {
    width: 50%;
    border: 1px solid #0056cb;
    margin-top: 10px;
  }
`;

const ParentItem = styled(ListItem)`
  span {
    color: #ffffff;
  }
`;

export const LinkItem = styled(ListItem)`
  span {
    color: #fff;
    padding-left: 15px;
  }
`;

const menus = [
  {
    label: "PRODUCT",
    icon: <ExtensionIcon color="primary" />,
    children: [
      {
        label: "List",
        link: "/products/list",
        icon: <ListAltIcon color="primary" />
      },
      {
        label: "Add",
        link: "/products/add",
        icon: <AddIcon color="primary" />
      }
    ]
  },
  {
    label: "TAG",
    icon: <LocalOfferIcon color="primary" />,
    children: [
      {
        label: "List",
        link: "/tags/list",
        icon: <ListAltIcon color="primary" />
      },
      {
        label: "Add",
        link: "/tags/add",
        icon: <AddIcon color="primary" />
      }
    ]
  }
];

const AdminDrawer = ({
  open,
  drawerOpenWidth,
  drawerCloseWidth,
  handleToggleChange
}) => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  // const { resetProduct } = productActions;

  // const productRestHandler = () => {
  //   dispatch(resetProduct());
  // };

  return (
    <AppDrawer
      drawerOpenWidth={drawerOpenWidth}
      drawerCloseWidth={drawerCloseWidth}
      variant="permanent"
      open={open}
      classes={{ root: classes.drawerPaper }}
    >
      <DrawerToolbar>
        {open ? (
          <>
            <AppName>
              <Typography
                color="primary"
                variant="h6"
                align="center"
                style={{ flexGrow: 1 }}
              >
                BackOffice
              </Typography>
              <Divider light />
            </AppName>
            <IconButton onClick={handleToggleChange} align="right">
              <ChevronLeft color="secondary" />
            </IconButton>
          </>
        ) : (
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleToggleChange}
            edge="start"
          >
            <Menu />
          </IconButton>
        )}
      </DrawerToolbar>
      <Divider light />
      <Link
        to="/dashboard"
        // onClick={productRestHandler}
      >
        <ParentItem button>
          <ListItemIcon style={{ minWidth: "32px" }}>
            <HomeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={"DASHBOARD"} />
        </ParentItem>
      </Link>
      <List>
        {menus.map((item) => (
          <SidebarMenu key={item.label} item={item} />
        ))}
      </List>
    </AppDrawer>
  );
};

export default AdminDrawer;
