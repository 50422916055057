import React, { useContext } from "react";
import {
  Toolbar,
  IconButton,
  MenuItem,
  Popper,
  MenuList,
  ClickAwayListener,
  Grow,
  Paper,
  Avatar
} from "@material-ui/core";
import { format } from "date-fns";
import { Typography, Wrapper, Link } from "../../views/components";
import UserLogo from "../icons/UserIcon";
import HeaderLogo from "./HeaderLogo";
import AppBar from "@material-ui/core/AppBar";
import styled from "styled-components";
import { useAnchor } from "../../hooks";
import AuthContext from "../../context/AuthContext";

const AppHeader = styled(({ drawerOpenWidth, drawerCloseWidth, ...rest }) => (
  <AppBar {...rest} />
))`
  background-color: #ffffff;
  box-shadow: rgba(53, 64, 82, 0.05) 0px 0px 14px 0px;
  color: #000000;
  transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 100%;
`;

const SvgWrapper = styled(Avatar)`
  && {
    width: ${(props) => props.size + "px"};
    height: ${(props) => props.size + "px"};
    background-color: ${(props) => props.bgcolor};
  }
`;

const Header = ({ open, drawerOpenWidth, drawerCloseWidth }) => {
  const { logoutUser } = useContext(AuthContext);

  const [anchorEl, handleToggleChange] = useAnchor();

  const handleLogout = async () => {
    await logoutUser();
  };

  return (
    <AppHeader
      open={open}
      drawerOpenWidth={drawerOpenWidth}
      drawerCloseWidth={drawerCloseWidth}
    >
      <Toolbar>
        <Wrapper
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="65px"
        >
          <Wrapper width="200px">
            <Link to={"/"}>
              <HeaderLogo />
            </Link>
          </Wrapper>
          <Wrapper display="flex" justifyContent="flex-end" alignItems="center">
            <Typography px={4}>{format(new Date(), "MMMM dd yyyy")}</Typography>
            <Typography ml={1} px={3}></Typography>
            <IconButton
              aria-controls="logout-menu"
              aria-haspopup="true"
              onClick={handleToggleChange}
            >
              <SvgWrapper bgcolor="#fff" size="40">
                <UserLogo />
              </SvgWrapper>
            </IconButton>

            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: "right bottom"
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleToggleChange}>
                      <MenuList
                        autoFocusItem={Boolean(anchorEl)}
                        id="menu-list-grow"
                      >
                        <MenuItem onClick={handleLogout}>
                          <Typography px={2}>Logout</Typography>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Wrapper>
        </Wrapper>
      </Toolbar>
    </AppHeader>
  );
};

export default Header;
