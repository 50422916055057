import React, { useEffect, useState, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Dialog, Wrapper, Button, FullPageLoader } from "../../components";
import { productActions } from "../../../state/product";
import { notificationActions } from "../../../state/notification";
import EditForm from "./EditForm";
import styled from "styled-components";
import DialogContent from "./DialogContent";
import DialogActions from "./DialogActions";
import CreateTag from "../Tag/index";
// import RelationalInfo from "./RelationalFields.js";

import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="down" ref={ref} mountOnEnter unmountOnExit {...props} />
  );
});

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ProductHeader = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: base-line;
`;

const Product = (props) => {
  const [show, setShow] = useState(false);
  const [tagForm, setTagForm] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const product = useSelector(({ product }) => product);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const closeTagForm = () => setTagForm(false);
  const showTagForm = () => setTagForm(true);

  let id;
  if (location?.state?.rowData?.id) {
    id = location?.state?.rowData.id;
  } else {
    history.push("/products/list");
  }
  console.log(location?.state?.currentPage);
  const listPageData = useRef(product?.allProducts);
  // listPageData.current = product?.allProducts;

  const {
    getProduct,
    updateProduct,
    resetUpdateState,
    deleteProduct,
    resetDeleteState,
    resetProductGetState
  } = productActions;
  const { showNotification } = notificationActions;

  const updateHandler = (values) => {
    dispatch(updateProduct(id, values));
  };

  const deleteHandler = () => {
    handleClose();
    dispatch(deleteProduct(id));
  };

  useEffect(() => {
    return () => dispatch(resetProductGetState());
  }, [dispatch, resetProductGetState]);

  useEffect(() => {
    dispatch(getProduct(id));
  }, [id, dispatch, getProduct]);

  useEffect(() => {
    if (product.isDeletingProductDone) {
      dispatch(resetDeleteState());
      history.push("/products/list");
    }
  }, [dispatch, history, product, resetDeleteState]);

  useEffect(() => {
    if (product.isUpdatingProductDone) {
      dispatch(showNotification("update product successfully", "success"));
      listPageData.current = [
        ...product?.allProducts?.map((prd) => {
          if (prd.id === id) {
            prd = product?.productDetails;
          }

          return prd;
        })
      ];
      dispatch(resetUpdateState());
    }
    if (product.isUpdatingProductFailed) {
      dispatch(
        showNotification(product?.error || "failed to update Product", "error")
      );
      dispatch(resetUpdateState());
    }
  }, [dispatch, resetUpdateState, showNotification, product, id]);
  return (
    <Layout>
      <FullPageLoader
        isLoading={product?.isGettingProduct || product?.isDeletingProduct}
      />
      <Dialog
        transitionComponent={Transition}
        duration={300}
        open={show}
        heading="Please Confirm"
        handleClose={handleClose}
        scroll="body"
        maxWidth="xs"
        Content={() => <DialogContent />}
        ActionsContent={() => (
          <DialogActions
            deleteHandler={deleteHandler}
            handleClose={handleClose}
          />
        )}
      />
      <Dialog
        transitionComponent={Transition}
        duration={300}
        open={tagForm}
        handleClose={closeTagForm}
        scroll="body"
        maxWidth="sm"
        Content={() => <CreateTag />}
      />

      <ProductHeader>
        <Wrapper style={{ position: "absolute", top: 20, right: "13%" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleShow}
            style={{
              textTransform: "Capitalize",
              backgroundColor: "#F64D0A",
              borderRadius: "4px"
            }}
          >
            Delete
          </Button>
        </Wrapper>
        <Typography
          component="div"
          onClick={() =>
            history.push({
              pathname: "/products/list",

              state: {
                allRow: listPageData.current,
                cPage: location?.state?.currentPage
              }
            })
          }
          style={{ marginRight: "10px", cursor: "pointer" }}
        >
          <KeyboardBackspaceIcon fontSize="large" />
        </Typography>
        <Typography
          style={{
            textTransform: "uppercase",
            fontSize: "1.5rem",
            fontWeight: "bold"
          }}
        >
          Details
        </Typography>
      </ProductHeader>
      {product?.isGettingProductDone && (
        <EditForm
          product={product?.productDetails}
          updateHandler={updateHandler}
          showTagForm={showTagForm}
          updated={product?.productDetails?.updated_at}
          isPageLoading={product?.isUpdatingProduct}
        />
      )}
    </Layout>
  );
};

export default Product;
