import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import createStore from "./state/store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import GlobalStyle from "./views/styles/GlobalStyle";
import theme from "./views/styles/theme";
import { ThemeProvider } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";
import { AuthService } from "./services";

const Auth = new AuthService();

let initialState = {};

if (Auth.isLoggedIn()) {
  initialState = {
    auth: {
      isAuthenticated: true,
      token: Auth.getToken()
    }
  };
}

const store = createStore(initialState);
const MuiTheme = createTheme(theme);

ReactDOM.render(
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={MuiTheme}>
      <ThemeProvider theme={theme}>
        <Fragment>
          <CssBaseline />
          <GlobalStyle />
          <Provider store={store}>
            <App />
          </Provider>
        </Fragment>
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
