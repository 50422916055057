import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { productActions } from "../../../state/product";
import MaterialTable, { MTableToolbar } from "material-table";
import { Typography, TablePagination } from "../../components";
import columns from "./columns";
import CustomToolbar from "./Toolbar";

// import TablePagination from "./TablePagination";

const Layout = styled.div`
  display: "flex";
  flex-direction: column;
`;

const ProductHeader = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

// function capitalize(s) {
//   return s[0].toUpperCase() + s.slice(1).toLowerCase();
// }

const List = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation();

  // const [currentPage, setCurrentPage] = useState(1);

  const tableRef = useRef();

  const { getProducts } = productActions;

  const getData = async (query) => {
    query.page = query.page === 0 ? 1 : query.page - 1;
    let filter = {};
    if (query.search) {
      filter = {
        _q: query?.search
      };
    }
    if (query?.filters[0]?.value) {
      filter = {
        "tags.name_contains": query?.filters[0]?.value
      };
    }

    const start = query?.page === 0 ? 0 : query.page * query.pageSize;
    const res = await dispatch(
      getProducts({
        ...filter,
        _start: start,
        _sort: "updated_at:desc",
        _limit: query.pageSize
      })
    );

    return {
      data: res,
      totalCount: 1415,
      page: query.page
    };
  };

  return (
    <Layout>
      <ProductHeader>
        <Typography
          style={{
            textTransform: "uppercase",
            fontSize: "1.5rem",
            fontWeight: "bold",
            columnsButton: true,
            export: true
          }}
        >
          Product list
        </Typography>
      </ProductHeader>
      <MaterialTable
        data={getData}
        tableRef={tableRef}
        onRowClick={(event, rowData) => {
          history.push({
            pathname: "/products/details",
            state: {
              rowData
            }
          });
        }}
        options={{
          search: true,
          paginationType: "stepped",
          showTitle: false,
          pageSizeOptions: [25, 50, 100],
          pageSize: 100,
          filtering: true,
          debounceInterval: 400
          // columnsButton: true
        }}
        components={{
          Toolbar: (props) => {
            // localStorage.setItem("Columns", JSON.stringify(props?.columns));

            return (
              <Toolbar>
                <MTableToolbar {...props} />

                <CustomToolbar
                  columns={props.columns}
                  tableRef={tableRef}
                  hideColumn={props.onColumnsChanged}
                  {...props}
                />
              </Toolbar>
            );
          },
          // FilterRow: (props) => {
          //   return <FilterTags {...props} />;
          // },
          Pagination: (subProps) => {
            return <TablePagination component="div" {...subProps} />;
          }
        }}
        columns={columns}
      />
    </Layout>
  );
};

export default List;
