import React from "react";
import { Container } from "@material-ui/core";

import { Typography } from "../components";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  width: 100%;
  background: #ffffff;
  border-top: 1px solid #e0e0e0;
  padding: 8px;
`;

const Footer = () => (
  <FooterWrapper>
    <Container>
      <Typography align="center" variant="subtitle1">
        © {new Date().getFullYear()}
      </Typography>
    </Container>
  </FooterWrapper>
);

export default Footer;
