import { Form, withFormik } from "formik";
import * as yup from "yup";
import { Button, Wrapper, FieldErrorMsg, InputField } from "../../components";
import { Typography } from "@material-ui/core";

const Login = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  loading
}) => {
  return (
    <Form>
      <Wrapper>
        <Typography>E-mail</Typography>
        <InputField
          fullWidth
          name="email"
          value={values.email}
          margin="normal"
          variant="outlined"
          placeholder="Enter Email"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email}
        />
      </Wrapper>
      <FieldErrorMsg error={errors.email} isTouched={touched.email} />

      <Button
        fullWidth
        variant="contained"
        justify="flex-start"
        width="100%"
        mt={2}
        type="submit"
        color="primary"
        disabled={loading}
        loading={loading}
        style={{
          borderRadius: 4,
          color: "#fff",
          fontSize: 18,
          textTransform: "none",
          fontWeight: 500,
          lineHeight: "21px",
          marginBottom: 10
        }}
      >
        Sign in
      </Button>
    </Form>
  );
};

const LoginForm = withFormik({
  mapPropsToValues: () => ({
    email: ""
  }),
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email("Please provide a valid email")
      .required("Please enter your email")
  }),
  async handleSubmit(values, { props: { handleLogin } }) {
    handleLogin(values.email);
  }
})(Login);

export default LoginForm;
