import React from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

const RawHTMLContainer = styled.div`
  height: 100%;

  * {
    margin: 0;
    padding: 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  ${spacing};
`;

const RawHTML = ({ html, ...props }) => (
  <RawHTMLContainer dangerouslySetInnerHTML={{ __html: html }} {...props} />
);

export default RawHTML;
