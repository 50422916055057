import React, { Component, Fragment, useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";

export default function WithAuth(WrappedComponent) {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  class WithAuthentication extends Component {
    render() {
      return (
        <Fragment>
          {user ? (
            <WrappedComponent {...this.props} />
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { wPath: location.pathname }
              }}
            />
          )}
        </Fragment>
      );
    }
  }

  return WithAuthentication;
}
