import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  CharacterMetadata
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  entityMapperToComponent,
  customChunkRenderer,
  entityMapper
} from "../../utils/draftJSCustomHTML";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button
} from "@material-ui/core";

const styles = () => ({
  root: {
    border: "1px solid #cecece",

    "& .rdw-editor-wrapper": {
      background: "white"
    },

    "& .rdw-editor-main": {
      padding: "0 16px 16px 16px",
      minHeight: 300,
      maxHeight: 360
    },

    "& .mre-toolbar": {
      border: "none",
      borderBottom: "1px solid #F1F1F1",
      borderRadius: 0
    },

    "@media only screen and (max-width: 600px)": {
      "& .rdw-option-wrapper": {
        height: 20,
        minWidth: 16,

        "& img": {
          width: 16
        }
      },
      "& .mre-toolbar": {
        paddingBottom: 6
      }
    }
  },

  htmlEditorDialog: {
    width: "40vw",

    "& textarea": {
      border: "1px solid #F1F1F1",
      width: "100%"
    },

    "@media only screen and (max-width: 600px)": {
      width: "70vw"
    }
  }
});

const customBlockRenderFunc = function (block, config) {
  if (block.getType() === "atomic") {
    const contentState = config.getEditorState().getCurrentContent();
    const entity = contentState.getEntity(block.getEntityAt(0));
    return {
      component: entityMapperToComponent(entity),
      editable: false,
      props: {
        children: () => entity.innerHTML
      }
    };
  }
  return undefined;
};

const removeInlineStyles = (editorState, retainInlineStyles = []) => {
  let blocks = editorState
    .getCurrentContent()
    .getBlocksAsArray()
    .map((singleBlock) =>
      singleBlock.set(
        "characterList",
        singleBlock.getCharacterList().map((charMetaData) => {
          if (!charMetaData) {
            return charMetaData;
          }
          let entity = charMetaData.getEntity();
          let style = charMetaData.getStyle();
          return CharacterMetadata.create({
            entity: entity,
            style: style.intersect(retainInlineStyles)
          });
        })
      )
    );
  return EditorState.createWithContent(
    ContentState.createFromBlockArray(blocks)
  );
};

const toolbarOptions = {
  options: [
    "inline",
    "textAlign",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",

    "link",

    "history"
  ],
  inline: {
    options: ["bold", "italic", "underline", "strikethrough", "monospace"]
  },
  blockType: {
    inDropdown: true,
    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined
  },
  fontFamily: {
    options: [
      "Arial",
      "Georgia",
      "Impact",
      "Tahoma",
      "Times New Roman",
      "Verdana"
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined
  },
  list: {
    options: ["unordered", "ordered"]
  },
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: [
      "rgb(97,189,109)",
      "rgb(26,188,156)",
      "rgb(84,172,210)",
      "rgb(44,130,201)",
      "rgb(147,101,184)",
      "rgb(71,85,119)",
      "rgb(204,204,204)",
      "rgb(65,168,95)",
      "rgb(0,168,133)",
      "rgb(61,142,185)",
      "rgb(41,105,176)",
      "rgb(85,57,130)",
      "rgb(40,50,78)",
      "rgb(0,0,0)",
      "rgb(247,218,100)",
      "rgb(251,160,38)",
      "rgb(235,107,86)",
      "rgb(226,80,65)",
      "rgb(163,143,132)",
      "rgb(239,239,239)",
      "rgb(255,255,255)",
      "rgb(250,197,28)",
      "rgb(243,121,52)",
      "rgb(209,72,65)",
      "rgb(184,49,47)",
      "rgb(124,112,107)",
      "rgb(209,213,216)"
    ]
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link", "unlink"],
    link: {
      className: undefined
    },
    unlink: {
      className: undefined
    },
    linkCallback: undefined
  },
  image: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: false,
    alignmentEnabled: true,
    uploadCallback: undefined,
    previewImage: false,
    inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    alt: {
      present: false,
      mandatory: false
    },
    defaultSize: {
      height: "auto",
      width: "auto"
    }
  }
};

class MyRichEditor extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    showEditorCode: false,
    editorHTML: "",
    showCode: false
  };

  componentDidMount() {
    const editorContent = this.props.editorContent;
    const contentBlock = htmlToDraft(editorContent, customChunkRenderer);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState });
    }
  }

  handleEditorContentChange = (editorState) => {
    let value = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
      null,
      false,
      entityMapper
    );

    removeInlineStyles(editorState);

    this.props.onChange(this.props.keyName, value);
    this.setState({ editorState, editorHTML: value });
  };

  handleBlur = () => {
    this.props.onBlur(this.props.keyName, true);
  };

  handleHtmlEditorContentChange = ({ target: { value: editorHTML } }) =>
    this.setState({ editorHTML });

  handleToggleEditor = () => {
    const { showEditorCode, editorState } = this.state;
    if (!showEditorCode) {
      this.handleEditorContentChange(editorState);
    }
    this.setState({ showEditorCode: !showEditorCode });
  };

  handleAddHtmlToEditor = () => {
    const { editorHTML, showEditorCode } = this.state;
    const contentBlock = htmlToDraft(
      editorHTML[1] !== "p" ? `<p>${editorHTML}</p>` : editorHTML,
      customChunkRenderer
    );
    let editor;
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      editor = EditorState.createWithContent(contentState);
    } else {
      editor = EditorState.createEmpty();
    }

    this.props.onChange(this.props.keyName, editorHTML);
    this.setState({ editorState: editor, showEditorCode: !showEditorCode });
  };

  render() {
    const { editorState, showEditorCode, editorHTML } = this.state;
    const { classes } = this.props;

    const ShowEditorCode = () => (
      <div className="rdw-option-wrapper" onClick={this.handleToggleEditor}>
        {showEditorCode ? "Hide Code" : "Show Code"}
      </div>
    );

    return (
      <div className={classes.root}>
        <Editor
          editorState={editorState}
          onEditorStateChange={this.handleEditorContentChange}
          wrapperClassName="mre-wrapper"
          editorClassName="mre-editor"
          toolbarClassName="mre-toolbar"
          toolbar={toolbarOptions}
          onBlur={this.handleBlur}
          placeholder={this.props.placeholder}
          toolbarCustomButtons={[<ShowEditorCode />]}
          customBlockRenderFunc={customBlockRenderFunc}
        />
        <Dialog
          open={showEditorCode}
          onClose={this.handleToggleEditor}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className={classes.htmlEditorDialog}>
            <textarea
              rows={5}
              value={editorHTML}
              onChange={this.handleHtmlEditorContentChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleToggleEditor} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleAddHtmlToEditor}
              color="primary"
              autoFocus
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MyRichEditor);
