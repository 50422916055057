import moment from "moment";
import { Avatar } from "../../components";

// const historyCol = JSON.parse(localStorage.getItem("Columns"));

const columns = [
  {
    title: "Title",
    field: "title",
    render: ({ title }) => <div>{title}</div>,
    filtering: false,
    hidden: false
  },
  {
    title: "Author",
    field: "authorName",
    filtering: false,
    hidden: false,
    render: ({ author }) => (
      <div>
        {author?.username?.includes("@")
          ? author?.username?.split("@")[0]
          : author?.username}
      </div>
    )
  },
  {
    title: "price",
    field: "price",
    filtering: false,
    hidden: false,
    render: ({ price }) => <div>{price}</div>
  },
  {
    title: "image",
    field: "image",
    filtering: false,
    hidden: false,
    render: ({ image }) => (
      <img
        alt="img"
        src={image?.formats?.thumbnail?.url}
        style={{ width: 50, borderRadius: "50%" }}
      />
    )
  },
  {
    title: "tags",
    field: "tags",
    hidden: false,
    // filtering: false,
    render: ({ tags }) => (
      <div>{tags.map((tag) => tag.name.toLowerCase()).join(" , ")}</div>
    )
  },

  {
    title: "video",
    field: "video",
    filtering: false,
    hidden: false,
    render: ({ video }) => (
      <Avatar
        name="mp4"
        size={50}
        style={{ fontSize: "14px", background: "#f3f3f4", color: "#000000" }}
      />
    )
  },
  // {
  //   title: "Orders",
  //   field: "orders",
  //   render: ({ orders }) => <div>{`[${orders?.length}] orders`}</div>
  // },

  {
    title: "published_at",
    field: "published_at",
    filtering: false,
    hidden: true,
    width: 0,
    render: ({ published_at }) => (
      <div>{moment(published_at).format("do MMM yy")}</div>
    )
  },
  {
    title: "created_at",
    field: "created_at",
    hidden: true,
    filtering: false,
    width: 0,
    render: ({ created_at }) => (
      <div>{moment(created_at).format("do MMM yy")}</div>
    )
  }
];

// console.log("dkjfkdj", historyCol);

// if (historyCol) {
//   historyCol.forEach((col, i) => {
//     if (col.hidden !== columns[i].hidden) {
//       columns[i].hidden = col.hidden;
//     }
//   });
// }

export default columns;
