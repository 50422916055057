import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LoginPage,
  ForOFourPage,
  Home,
  ProductsAdd,
  ProductsList,
  ProductsEdit,
  Tag,
  TagsList
} from "../views/pages";
import { withAuth } from "../services";

export default function Routes(props) {
  return (
    <Switch>
      <Route exact path="/signin" component={LoginPage} />

      <Route path="/dashboard" component={Home} />

      <Route path="/products/add" component={withAuth(ProductsAdd)} />
      <Route path="/products/details" component={withAuth(ProductsEdit)} />
      <Route path="/products/list" component={withAuth(ProductsList)} />

      <Route path="/tags/add" component={withAuth(Tag)} />
      <Route path="/tags/list" component={withAuth(TagsList)} />

      <Redirect exact from="/" to="/dashboard" />
      <Route path="/*" component={ForOFourPage} />
    </Switch>
  );
}
