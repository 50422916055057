import React, { useCallback } from "react";
import { CloudUploadOutlined } from "@material-ui/icons";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
// import { FullPageLoader } from "../../components";

const DropZoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  border: 3px dashed yellow;
  padding: 30px;
  margin-bottom: 50px;
`;

function FileUploadForm({ handleFileInfo, fileName, isPageLoading }) {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      handleFileInfo(acceptedFiles[0]);
    },
    [handleFileInfo]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "application/json"
  });

  return (
    <>
      {/* <FullPageLoader isLoading={isPageLoading} /> */}
      <DropZoneWrapper {...getRootProps()}>
        <input {...getInputProps()} />
        <span className="upload-icon">
          <CloudUploadOutlined />
        </span>
        <p>Drag & Drop a file here</p>
        <div>
          <button type="button" className="select-button">
            Select Json File
          </button>
        </div>
        <Typography
          style={{ marginTop: "10px", color: "#000000", fontWeight: "bold" }}
        >
          {fileName ? fileName : ""}
        </Typography>
      </DropZoneWrapper>
    </>
  );
}

export default FileUploadForm;
