import React, { useEffect, useContext, useState } from "react";
import { Wrapper } from "../../components";
import useRouter from "../../../hooks/useRouter";
import AuthContext from "../../../context/AuthContext";
import { Redirect } from "react-router-dom";
import Progress from "../../layouts/progress";

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const { user, allowUser, finishSocialLogin } = useContext(AuthContext);
  const router = useRouter();

  useEffect(() => {
    if (router?.query?.provider) {
      setLoading(true);
      (async () => {
        await finishSocialLogin();
      })();
    }
  }, [router.query.provider, finishSocialLogin]);

  // if (!router?.query?.provider && !user?.email) {
  //   router.push("/signin");
  // }

  useEffect(() => {
    if (user?.email) {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (allowUser?.access) {
      router.push("/signin");
    }
  }, [allowUser, router]);

  const bool = !!router?.query?.provider || user?.email;
  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 0,
          left: 0
        }}
      >
        <Progress />
      </div>
    );
  }

  return (
    <>
      {!bool && <Redirect to="signin" />}
      {/* <FullPageLoader isLoading={loading} style={{ background: "#ffffff" }} /> */}
      {!loading && (
        <Wrapper
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100%"
        >
          <h2>Welcome Motion pixels Dashboard</h2>
        </Wrapper>
      )}
    </>
  );
};

export default HomePage;
