import React from "react";
import { Typography } from "./";

const FieldErrorMsg = ({ error, isTouched }) => {
  return (
    <>
      {isTouched && error && (
        <Typography mt={1} variant="subtitle1" color="error" align="left">
          {error}
        </Typography>
      )}
    </>
  );
};

export default FieldErrorMsg;
