import React from "react";
import { CircularProgress, Backdrop } from "@material-ui/core";
import styled from "styled-components";

const FullBackdrop = styled(Backdrop)`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
`;

const FullPageLoader = ({ isLoading = false, ...props }) => (
  <FullBackdrop color="primary" open={isLoading}>
    <CircularProgress></CircularProgress>
  </FullBackdrop>
);

export default FullPageLoader;
