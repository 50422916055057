import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LayerIcon from "./LayerIcon";
// import Searchbar from "./Searchbar";
import { ColumnToggle } from "../../components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "24px 16px",
    position: "relative",
    zIndex: 50,
    "@media only screen and (max-width: 1366px)": {
      flexWrap: "wrap"
    }
  },
  toolbarLeft: {
    display: "flex",
    alignItems: "flex-end",
    "& > svg": {
      position: "relative",
      top: "-13px"
    },
    "& > svg, > div": {
      marginRight: 16
    }
  },
  textField: {
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)",
      fontSize: 14
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)"
    },
    "& .Mui-disabled .MuiInputBase-input": {
      color: "#979797",
      backgroundColor: "#F4F4F4"
    },
    "& .MuiInputBase-input": {
      minHeight: 38,
      padding: "0 14px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E7E7E7"
    }
  },
  toolbarRight: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    "@media only screen and (max-width: 1366px)": {
      margin: "15px 0 0 32px"
    }
  },
  filter: {
    textTransform: "none"
  },
  iconButton: {
    border: "1px solid #DDDBE1",
    borderRadius: 4,
    minWidth: 40,
    minHeight: 37,
    padding: 0,
    marginLeft: 16
  }
});

const Toolbar = ({ columns, hideColumn, tableRef, ...props }) => {
  const classes = useStyles();
  const [openToggle, setOpenToggle] = useState(false);

  const handleColumnToggle = () => {
    setOpenToggle(!openToggle);
  };

  // const handleSearch = (values) => {
  //   tableRef.current.onQueryChange({
  //     ...tableRef.current.state.query,
  //     page: 0,
  //     filter: {
  //       search: values?.search
  //     }
  //   });
  // };

  return (
    <div className={classes.root}>
      {/* <div style={{ flexBasis: "50%" }}>
        <Searchbar handleSearch={handleSearch} />
      </div> */}
      <div className={classes.toolbarRight}>
        <IconButton
          classes={{ root: classes.iconButton }}
          onClick={handleColumnToggle}
        >
          {openToggle ? (
            <CloseIcon />
          ) : (
            <LayerIcon stroke="#4A4A4A" width={15} height={15} />
          )}
        </IconButton>
        {openToggle && (
          <ColumnToggle columns={columns} hideColumn={hideColumn} />
        )}
      </div>
    </div>
  );
};
export default Toolbar;
