import * as types from "./types";

const initialState = {
  productData: {},
  productDetails: {},
  allProducts: [],
  count: 0
};

const productReducers = function (state = initialState, { type, payload }) {
  switch (type) {
    case types.ADD_PRODUCT:
      return {
        ...state,
        productData: {},
        isAddingProduct: true,
        isAddingProductDone: false,
        isAddingProductFailed: false
      };
    case types.ADD_PRODUCT_DONE:
      return {
        ...state,
        isAddingProduct: false,
        isAddingProductDone: true,
        isAddingProductFailed: false,
        productData: payload
      };
    case types.ADD_PRODUCT_FAILED:
      return {
        ...state,
        productData: {},
        isAddingProduct: false,
        isAddingProductDone: false,
        isAddingProductFailed: true
      };

    case types.GET_PRODUCT:
      return {
        ...state,
        productDetails: {},
        isGettingProduct: true,
        isGettingProductFailed: false,
        isGettingProductDone: false
      };
    case types.GET_PRODUCT_DONE:
      return {
        ...state,
        productDetails: payload,
        isGettingProduct: false,
        isGettingProductFailed: false,
        isGettingProductDone: true
      };
    case types.GET_PRODUCT_FAILED:
      return {
        ...state,
        productDetails: {},
        isGettingProduct: false,
        isGettingProductFailed: true,
        isGettingProductDone: false
      };

    case types.GET_PRODUCTS:
      return {
        ...state,
        allProducts: [],
        isGettingProducts: true,
        isGettingProductsFailed: false,
        isGettingProductsDone: false
      };
    case types.GET_PRODUCTS_DONE:
      return {
        ...state,
        allProducts: payload,
        isGettingProducts: false,
        isGettingProductsFailed: false,
        isGettingProductsDone: true
      };
    case types.GET_PRODUCTS_FAILED:
      return {
        ...state,
        allProducts: [],
        isGettingProducts: false,
        isGettingProductsFailed: true,
        isGettingProductsDone: false
      };

    case types.UPDATE_PRODUCT:
      return {
        ...state,
        // productDetails: {},
        isUpdatingProduct: true,
        isUpdatingProductFailed: false,
        isUpdatingProductDone: false
      };
    case types.UPDATE_PRODUCT_DONE:
      return {
        ...state,
        productDetails: payload,
        isUpdatingProduct: false,
        isUpdatingProductFailed: false,
        isUpdatingProductDone: true
      };

    case types.UPDATE_PRODUCT_FAILED:
      return {
        ...state,
        // productDetails: {},
        error: payload?.data?.message,
        isUpdatingProduct: false,
        isUpdatingProductFailed: true,
        isUpdatingProductDone: false
      };
    case types.DELETE_PRODUCT:
      return {
        ...state,
        // productDetails: {},
        isDeletingProduct: true,
        isDeletingProductFailed: false,
        isDeletingProductDone: false
      };
    case types.DELETE_PRODUCT_DONE:
      return {
        ...state,
        // productDetails: payload,
        isDeletingProduct: false,
        isDeletingProductFailed: false,
        isDeletingProductDone: true
      };

    case types.DELETE_PRODUCT_FAILED:
      return {
        ...state,
        // productDetails: {},
        isDeletingProduct: false,
        isDeletingProductFailed: true,
        isDeletingProductDone: false
      };
    case types.GET_PRODUCT_COUNT:
      return {
        ...state,
        count: 0,
        isGettingProductCount: true,
        isGettingProductCountFailed: false,
        isGettingProductDone: false
      };
    case types.GET_PRODUCT_COUNT_DONE:
      return {
        ...state,
        count: payload,
        isGettingProductCount: false,
        isGettingProductCountFailed: false,
        isGettingProductDone: true
      };
    case types.GET_PRODUCT_COUNT_FAILED:
      return {
        ...state,
        count: 0,
        isGettingProductCount: false,
        isGettingProductCountFailed: true,
        isGettingProductDone: false
      };

    case types.RESET_PRODUCT_GET_STATE:
      return {
        ...state,
        productDetails: {},
        isGettingProduct: false,
        isGettingProductDone: false,
        isGettingProductFailed: false
      };

    case types.SAVE_FILE_NAME_TO_STRAPI:
      return {
        ...state,
        isSavingFileToStrapi: true,
        isSavingFileToStrapiDone: false,
        isSavingFileToStrapiFalse: false
      };

    case types.SAVE_FILE_NAME_TO_STRAPI_DONE:
      return {
        ...state,
        isSavingFileToStrapi: false,
        isSavingFileToStrapiDone: true,
        isSavingFileToStrapiFalse: false
      };

    case types.SAVE_FILE_NAME_TO_STRAPI_FAILED:
      return {
        ...state,
        isSavingFileToStrapi: false,
        isSavingFileToStrapiDone: false,
        isSavingFileToStrapiFalse: true
      };

    case types.RESET_FILE_SAVE_STATE:
      return {
        ...state,
        isSavingFileToStrapi: false,
        isSavingFileToStrapiDone: false,
        isSavingFileToStrapiFalse: false
      };

    case types.RESET_UPDATE_STATE:
      return {
        ...state,
        isUpdatingProduct: false,
        isUpdatingProductDone: false,
        isUpdatingProductFailed: false,
        error: ""
      };
    case types.RESET_DELETE_STATE:
      return {
        ...state,
        isDeletingProduct: false,
        isDeletingProductDone: false,
        isDeletingProductFailed: false
      };
    case types.RESET_ADD_STATE:
      return {
        ...state,
        isAddingProduct: false,
        isAddingProductDone: false,
        isAddingProductFailed: false,
        productData: {}
      };

    default:
      return state;
  }
};

export default productReducers;
