import { useState } from "react";

export default function useAnchor(initValue = null) {
  const [anchorEl, setAnchorEl] = useState(initValue);

  const handleToggleChange = (event) => {
    if (anchorEl === event?.currentTarget) return;
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return [anchorEl, handleToggleChange];
}
