import * as types from "./types";

export const addProduct = (body) => ({
  type: types.ADD_PRODUCT,
  meta: {
    body,
    async: true,
    blocking: true,
    path: `/products`,
    method: "POST",
    isPrivate: true
  }
});

export const getProduct = (id) => ({
  type: types.GET_PRODUCT,
  meta: {
    async: true,
    blocking: true,
    path: `/products/${id}`,
    method: "GET"
  }
});

export const getProducts = (params) => ({
  type: types.GET_PRODUCTS,
  meta: {
    params,
    async: true,
    blocking: true,
    path: `/products`,
    method: "GET",
    isPrivate: false
  }
});

export const updateProduct = (id, body) => ({
  type: types.UPDATE_PRODUCT,
  meta: {
    body,
    async: true,
    blocking: true,
    path: `/products/${id}`,
    method: "PUT",
    isPrivate: true
  }
});

export const deleteProduct = (id) => ({
  type: types.DELETE_PRODUCT,
  meta: {
    async: true,
    blocking: true,
    path: `/products/${id}`,
    method: "DELETE",
    isPrivate: true
  }
});

export const getProductCount = () => ({
  type: types.GET_PRODUCT_COUNT,
  meta: {
    async: true,
    blocking: true,
    path: `/products/count`,
    method: "GET",
    isPrivate: true
  }
});

export const saveFileName = (id, body) => ({
  type: types.SAVE_FILE_NAME_TO_STRAPI,
  meta: {
    body,
    async: true,
    blocking: true,
    path: `/products/${id}`,
    method: "PUT",
    isPrivate: true
  }
});

export const resetProductGetState = () => ({
  type: types.RESET_PRODUCT_GET_STATE,
  meta: {
    blocking: true
  }
});

export const resetProductAddState = () => ({
  type: types.RESET_ADD_STATE
});

export const resetFileSaveState = () => ({
  type: types.RESET_FILE_SAVE_STATE
});

export const resetUpdateState = () => ({
  type: types.RESET_UPDATE_STATE
});

export const resetDeleteState = () => ({
  type: types.RESET_DELETE_STATE
});
