export const CREATE_TAG = "tag/create_tag";
export const CREATE_TAG_DONE = "tag/create_tag_done";
export const CREATE_TAG_FAILED = "tag/create_tag_failed";

export const DELETE_TAG = "tags/delete_tag";
export const DELETE_TAG_DONE = "tags/delete_tag_done";
export const DELETE_TAG_FAILED = "tags/delete_tag_failed";

export const RESET_TAG_STATE = "tags/reset_tag_state";

export const GET_TAG_COUNT = "tags/get_tag_count";
export const GET_TAG_COUNT_DONE = "tags/get_tag_count_done";
export const GET_TAG_COUNT_FAILED = "tags/get_tag_count_failed";

export const GET_TAGS = "tags/get_tags";
export const GET_TAGS_DONE = "tags/get_tags_done";
export const GET_TAGS_FAILED = "tags/get_tags_failed";

export const RESET_GET_TAGS_STATE = "tags/reset_get_tags_state";
