import React from "react";
import styled from "styled-components";
import {
  Wrapper,
  Typography,
  InputField,
  Image,
  FieldErrorMsg
} from "../../components";
import { Grid } from "@material-ui/core";

const ImgWrapper = styled.div`
  background: #333740;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  img {
    width: 100%;
    height: 100%;
  }
`;

const VideoWrapper = styled.div`
  background: #333740;
  padding: 10px;
`;

const ProductForm = ({ values, handleChange, errors, touched }) => {
  return (
    <Wrapper style={{ width: "100%" }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography>Title</Typography>
          <InputField
            fullWidth
            size="small"
            name="title"
            value={values.title}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>Author</Typography>
          <InputField
            fullWidth
            size="small"
            name="authorName"
            disabled
            value={
              values?.author?.username?.includes("@")
                ? values.author.username.split("@")[0]
                : values.author.username
            }
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography>Video</Typography>
          <VideoWrapper>
            <video
              style={{ width: "100%", height: "100%" }}
              playsInline
              autoPlay="autoplay"
              muted
              loop="loop"
              poster={values?.image?.formats?.thumbnail?.url}
            >
              <source type="video/mp4" src={values?.video?.url} />
            </video>
          </VideoWrapper>
        </Grid>
        <Grid item xs={6}>
          <Typography mt={4}>Price</Typography>
          <InputField
            fullWidth
            name="price"
            size="small"
            value={values.price}
            onChange={handleChange}
            variant="outlined"
          />
          <FieldErrorMsg error={errors.price} isTouched={touched.price} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>Description</Typography>
          <InputField
            fullWidth
            onChange={handleChange}
            name="description"
            value={values.description}
            placeholder="Enter description here..."
            multiline
            variant="outlined"
            color="secondary"
            rows="4"
            cols="10"
          />
          <FieldErrorMsg
            error={errors.description}
            isTouched={touched.description}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography>Image</Typography>
          <ImgWrapper>
            <Image src={values?.image?.formats?.thumbnail?.url} />
          </ImgWrapper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ProductForm;
