import React from "react";
import Grid from "@material-ui/core/Grid";
import styled from 'styled-components';

const StyledGrid = styled(Grid)`
  margin-right: -15px; 
  margin-left: -15px; 
  width: auto; 
`;

export default function GridContainer({ children, ...rest }) {

  return (
    <StyledGrid container {...rest} >
      {children}
    </StyledGrid>
  );
}


