import * as types from "./types";

const initialState = {
  signedURLInfo: {},
  document: {}
};

const documentReducers = function (state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_S3_URL:
      return {
        ...state,
        isGettingSignedURL: true,
        getSignedURLDone: false,
        getSignedURLFailed: false,
        errorMessage: null
      };
    case types.GET_S3_URL_DONE:
      return {
        ...state,
        isGettingSignedURL: false,
        getSignedURLDone: true,
        getSignedURLFailed: false,
        signedURLInfo: payload.data
      };
    case types.GET_S3_URL_FAILED:
      return {
        ...state,
        isGettingSignedURL: false,
        getSignedURLDone: false,
        getSignedURLFailed: true,
        signedURLInfo: {},
        errorMessage: payload?.data?.message?.title
      };
    case types.UPLOAD_DOCUMENT:
      return {
        ...state,
        isUploadingDocument: true,
        uploadDocumentDone: false,
        uploadDocumentFailed: false,
        errorMessage: null
      };
    case types.UPLOAD_DOCUMENT_DONE:
      return {
        ...state,
        isUploadingDocument: false,
        uploadDocumentDone: true,
        uploadDocumentFailed: false,
        errorMessage: null
      };
    case types.UPLOAD_DOCUMENT_FAILED:
      return {
        ...state,
        isUploadingDocument: false,
        uploadDocumentDone: false,
        uploadDocumentFailed: true,
        errorMessage: payload.message
      };

    case types.DOCUMENT_UPLOAD_RESET: 
        return {
          ...state,
        isUploadingDocument: true,
        uploadDocumentDone: false,
        uploadDocumentFailed: false,
        errorMessage: null
        }
    case types.RESET_UPLOAD_ERROR:
    case types.RESET_UPLOADER:
      return {
        ...state,
        signedURLInfo: {},
        isUploadingDocument: false,
        uploadDocumentDone: false,
        isUploadingVideoDone: false,
        uploadDocumentFailed: false,
        isGettingSignedURL: false,
        getSignedURLDone: false,
        getSignedURLFailed: false,
        isUploadingImageDone: false,
        errorMessage: null
      };
    case types.IMAGE_UPLOAD:
      return {
        ...state,

        isUploadingImage: true,
        isUploadingImageDone: false,
        isUploadingImageFailed: false
      };
    case types.IMAGE_UPLOAD_DONE:
      return {
        ...state,
        isUploadingImage: false,
        isUploadingImageDone: true,
        isUploadingImageFailed: false
      };
    case types.IMAGE_UPLOAD_FAILED:
      return {
        ...state,

        isUploadingImage: false,
        isUploadingImageDone: false,
        isUploadingImageFailed: true
      };
    case types.VIDEO_UPLOAD:
      return {
        ...state,

        isUploadingVideo: true,
        isUploadingVideoDone: false,
        isUploadingVideoFailed: false
      };
    case types.VIDEO_UPLOAD_DONE:
      return {
        ...state,
        isUploadingVideo: false,
        isUploadingVideoDone: true,
        isUploadingVideoFailed: false
      };
    case types.VIDEO_UPLOAD_FAILED:
      return {
        ...state,

        isUploadingVideo: false,
        isUploadingVideoDone: false,
        isUploadingVideoFailed: true
      };

    case types.GIF_UPLOAD:
      return {
        ...state,

        isUploadingGif: true,
        isUploadingGifDone: false,
        isUploadingGifFailed: false
      };
    case types.GIF_UPLOAD_DONE:
      return {
        ...state,
        isUploadingGif: false,
        isUploadingGifDone: true,
        isUploadingGifFailed: false
      };
    case types.GIF_UPLOAD_FAILED:
      return {
        ...state,

        isUploadingGif: false,
        isUploadingGifDone: false,
        isUploadingGifFailed: true
      };

    default:
      return state;
  }
};

export default documentReducers;
