import fetch from "../utils/axios";
import { api, api_document } from "../config/";
import * as types from "../state/auth/types";
import * as documentTypes from "../state/document/types";
import { Magic } from "magic-sdk";
import { MAGIC_PUBLIC_KEY } from "../utils/URLUtils";
import querystring from "querystring";

// import AuthService from "./AuthService";

const docTypes = [
  documentTypes.IMAGE_UPLOAD,
  documentTypes.GET_S3_URL,
  documentTypes.VIDEO_UPLOAD
];

const magic = new Magic(MAGIC_PUBLIC_KEY);

export const getToken = async () => {
  try {
    const token = await magic.user.getIdToken();
    return token;
  } catch (err) {
    console.log(err);
  }
};

const apiService = () => (next) => async (action) => {
  const result = next(action);
  if (!action.meta || !action.meta.async) {
    return result;
  }
  const {
    path,
    method = "GET",
    body,
    type,
    params,
    isPrivate = false
  } = action.meta;
  let token = isPrivate ? await getToken() : "";

  if (token) {
    token = `Bearer ${token}`;
  }

  if (!path) {
    throw new Error(`'path' not specified for async action ${action.type}`);
  }

  let url;

  if (docTypes.includes(action.type)) {
    url = `${api_document}${path}`;
  } else {
    url = `${api}${path}${params ? `?${querystring.stringify(params)}` : ""}`;
  }

  return fetch({ url, method, body, type, token })
    .then((res) => handleResponse(res, action, next))
    .catch((error) => {
      if (error.response) {
        return handleErrors(error.response, action, next);
      } else if (error.request) {
        return handleErrors(error.request, action, next);
      }
      return handleErrors(error.message, action, next);
    });
};

function handleErrors(err, action, next) {
  if (err.status === 401) {
    next({
      type: types.SET_USER_UNAUTHORIZED,
      payload: err,
      meta: action.meta
    });
  } else {
    next({
      type: `${action.type}_failed`,
      payload: err,
      meta: action.meta
    });
  }

  return err;
}

function handleResponse(res, action, next) {
  // if (loginActions.includes(action.type)) {
  //   let { token, user } = res.data;
  //   Auth.setToken(token);
  //   Auth.setUser(user);
  // } else if (action.type === "users/get_profile") {
  // } else if (action.type === "auth/logout") {
  //   Auth.removeToken();
  // }

  next({
    type: `${action.type}_done`,
    payload: res,
    meta: action.meta
  });

  return res;
}

export default apiService;
