import React from "react";
import { Wrapper, Typography } from "../../components";

const DialogContent = () => {
  return (
    <Wrapper display="flex" justifyContent="center" alignItems="center" mt={3}>
      <Typography
        style={{
          color: "#F64D0A"
        }}
      >
        Are you sure, you want to delete this entry
      </Typography>
    </Wrapper>
  );
};

export default DialogContent;
