import React, { useContext, useEffect, useState } from "react";
import { useToggle } from "../../hooks";
import { Header, Sidebar, Container } from "../components";
import styled from "styled-components";
import AuthContext from "../../context/AuthContext";
import Progress from "./progress";

const PageWrapper = styled.div`
  display: flex;
`;

const Main = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #fafafa;
  position: relative;
`;

const AuthContent = styled(Container)`
  margin-top: 80px;
  height: 100%;
  /* ${Container} */
`;

const drawerOpenWidth = 240;
const drawerCloseWidth = 50;

const Layout = ({ children }) => {
  const [open, handleToggleChange] = useToggle(true);
  const [loading, setLoading] = useState(false);
  const { user, checkUserLoggedIn } = useContext(AuthContext);
  useEffect(() => {
    if (!user?.email) {
      (async () => {
        setLoading(true);
        await checkUserLoggedIn();
        setLoading(false);
      })();
    }
  }, [checkUserLoggedIn, user]);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Progress />
      </div>
    );
  }
  return (
    <PageWrapper>
      <>
        {user?.email && (
          <Header
            open={open}
            drawerOpenWidth={drawerOpenWidth}
            drawerCloseWidth={drawerCloseWidth}
          />
        )}
        {user?.email && (
          <Sidebar
            handleToggleChange={handleToggleChange}
            drawerOpenWidth={drawerOpenWidth}
            drawerCloseWidth={drawerCloseWidth}
            open={open}
          />
        )}
        <Main>
          <AuthContent>{children}</AuthContent>
        </Main>
      </>
    </PageWrapper>
  );
};

export default Layout;
