export const GET_S3_URL = "document/get_s3_url";
export const GET_S3_URL_DONE = "document/get_s3_url_done";
export const GET_S3_URL_FAILED = "document/get_s3_url_failed";

export const UPLOAD_DOCUMENT = "document/upload";
export const UPLOAD_DOCUMENT_DONE = "document/upload_done";
export const UPLOAD_DOCUMENT_FAILED = "document/upload_failed";

export const RESET_UPLOAD_ERROR = "document/reset_upload_error";
export const RESET_UPLOADER = "document/reset_uploader";

export const IMAGE_UPLOAD = "document/image_upload";
export const IMAGE_UPLOAD_DONE = "document/image_upload_done";
export const IMAGE_UPLOAD_FAILED = "document/image_upload_failed";

export const VIDEO_UPLOAD = "document/video_upload";
export const VIDEO_UPLOAD_DONE = "document/video_upload_done";
export const VIDEO_UPLOAD_FAILED = "document/video_upload_failed";

export const GIF_UPLOAD = "document/gif_upload";
export const GIF_UPLOAD_DONE = "document/gif_upload_done";
export const GIF_UPLOAD_FAILED = "document/gif_upload_failed";


export const DOCUMENT_UPLOAD_RESET = 'document/document_upload_reset'