import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { tagActions } from "../../../state/tag";
import { notificationActions } from "../../../state/notification";
import { Paper } from "../../components";
import styled from "styled-components";
import CreateForm from "./CreateForm";

const ProductLayer = styled.div`
  display: flex;
  min-height: 100%;
`;

const ProductFields = styled(Paper)`
  flex-basis: 75%;
  background-color: #ffffff;
  margin-right: 2%;
  min-height: 100%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
`;
const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductHeader = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: base-line;
`;

const Product = (props) => {
  const dispatch = useDispatch();
  const tag = useSelector(({ tag }) => tag);

  const { createTag, resetTagState } = tagActions;
  const { showNotification } = notificationActions;

  const handleCreateTag = async (values) => {
    // console.log(values);
    dispatch(createTag(values));
  };

  useEffect(() => {
    if (!tag.isCreatingTag && tag.isCreatingTagDone) {
      dispatch(showNotification("tag saved successfully", "success"));

      dispatch(resetTagState());
    }
    if (tag.isCreatingTagFailed) {
      dispatch(showNotification("Failed to save tag", "error"));
      dispatch(resetTagState());
    }
  }, [dispatch, tag, showNotification, resetTagState]);

  return (
    <Layout>
      <ProductHeader>
        <Typography
          style={{
            textTransform: "uppercase",
            fontSize: "1.5rem",
            fontWeight: "bold"
          }}
        >
          Create New Tag
        </Typography>
      </ProductHeader>
      <ProductLayer>
        <ProductFields>
          <Container>
            <CreateForm
              handleCreateTag={handleCreateTag}
              loading={tag?.isCreatingTag}
              done={tag?.isCreatingTagDone}
            />
          </Container>
        </ProductFields>
      </ProductLayer>
    </Layout>
  );
};

export default Product;
