import { createContext, useState } from "react";
import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import { MAGIC_PUBLIC_KEY, CX } from "../utils/URLUtils";
import useRouter from "../hooks/useRouter";
const magic = new Magic(MAGIC_PUBLIC_KEY, {
  extensions: [new OAuthExtension()]
});

const userList = [
  "adam@adecor.com",
  "latif.bd1789@gmail.com",
  "arif@adecor.com",
  "docucave@gmail.com",
  "mahmudarif1175@gmail.com"
];

const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [allowUser, setAllowUser] = useState(null);
  const router = useRouter();

  /**
   * Log the user in
   * @param {string} email
   */
  const loginUser = async (email, redirectUri) => {
    try {
      if (userList.includes(email)) {
        await magic.auth.loginWithMagicLink({ email });
        setUser({ email });
        if (redirectUri === "/") {
          router.push(`${redirectUri}`);
        } else {
          router.push(`/${redirectUri}`);
        }
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const startSocialLogin = async (redirectUri) => {
    let RD_URI = CX;

    if (redirectUri && redirectUri !== "/") {
      RD_URI = `${CX}/${redirectUri}`;
    }

    try {
      await magic.oauth.loginWithRedirect({
        provider: "google",
        redirectURI: RD_URI
      });
    } catch (err) {
      console.log(err);
    }
  };
  const finishSocialLogin = async () => {
    try {
      const {
        oauth: {
          userInfo: { email }
        }
      } = await magic.oauth.getRedirectResult();
      if (userList.includes(email)) {
        setUser({ email });
        return true;
      } else {
        setAllowUser({ access: true });
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Log the user out
   */
  const logoutUser = async () => {
    try {
      await magic.user.logout();
      setUser(null);
      router.push("/signin");
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * If user is logged in, get data and display it
   */
  const checkUserLoggedIn = async () => {
    try {
      const isLoggedIn = await magic.user.isLoggedIn();

      if (isLoggedIn) {
        const { email } = await magic.user.getMetadata();
        if (userList.includes(email)) {
          setUser({ email });
          return true;
        }

        // const token = await magic.user.getIdToken();
        // console.log(token);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getToken = async () => {
    try {
      const token = await magic.user.getIdToken();
      return token;
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Reload user login on app refresh
   */
  // useEffect(() => {
  //   checkUserLoggedIn();
  // }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        allowUser,
        logoutUser,
        loginUser,
        getToken,
        startSocialLogin,
        finishSocialLogin,
        checkUserLoggedIn
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
