import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Wrapper } from "../../components";
import { productActions } from "../../../state/product";
import { notificationActions } from "../../../state/notification";

import ProductForm from "./ProductForm";

const ProductCreateContainer = ({ setStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const product = useSelector(({ product }) => product);

  const { addProduct } = productActions;
  const { showNotification } = notificationActions;

  const handleAddDetails = async (values) => {
    dispatch(addProduct(values));
  };

  useEffect(() => {
    if (!product.isAddingProduct && product.isAddingProductDone) {
      dispatch(showNotification("created successfully", "success"));
      setStep("2");
      // return () => dispatch(resetProductAddState());
    }
  }, [
    dispatch,
    product,
    showNotification,
    // resetProductAddState,
    history,
    setStep
  ]);

  return (
    <>
      <Wrapper
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
        width="100%"
        maxWidth="1100px"
        marginTop="40px"
      >
        <ProductForm
          handleAddDetails={handleAddDetails}
          product={product?.productData}
          isPageLoading={product?.isAddingProduct}
        />
      </Wrapper>
    </>
  );
};

export default ProductCreateContainer;
