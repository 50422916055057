import * as types from "./types";

const initialState = {
  snackbarOpen: false,
  variant: "info",
  snackbarMessage: ""
};

const notificationsReducers = function (state = initialState, actions) {
  switch (actions.type) {
    case types.SNACKBAR_OPEN:
      return {
        ...state,
        snackbarOpen: true,
        variant: actions.meta.variant,
        snackbarMessage: actions.meta.message
      };

    case types.SNACKBAR_CLEAR:
      return {
        ...state,
        snackbarOpen: false,
        snackbarMessage: ""
      };
    default:
      return state;
  }
};

export default notificationsReducers;
