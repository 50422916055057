import React, { Fragment } from "react";
import styled from "styled-components";
import { Link } from "../components";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";

import {
  Divider,
  ListItemIcon,
  ListItemText,
  ListItem
} from "@material-ui/core";
import { useToggle } from "../../hooks";

const ParentItem = styled(ListItem)`
  span {
    color: #ffffff;
  }
`;

const MenuChildren = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-out 0s;
  .MuiListItemText-root {
    color: "#fff";
  }
`;

export const LinkItem = styled(ListItem)`
  span {
    color: #fff;
    padding-left: 15px;
  }
`;

const SidebarMenu = ({ item }) => {
  const [openDropdown, setOpenDropdown] = useToggle(true);

  return (
    <>
      <Fragment>
        <ParentItem onClick={setOpenDropdown} button>
          <ListItemIcon style={{ minWidth: "32px" }}>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
          <ListItemIcon style={{ minWidth: "16px" }}>
            {openDropdown ? (
              <KeyboardArrowUp
                style={{ color: "#ffffff", marginRight: "10px" }}
              />
            ) : (
              <KeyboardArrowDown
                style={{ color: "#ffffff", marginRight: "10px" }}
              />
            )}
          </ListItemIcon>
        </ParentItem>
        <MenuChildren isOpen={openDropdown}>
          {item.children.map((menu) => (
            <Link
              to={menu.link}
              key={menu.link}
              // onClick={productRestHandler}
            >
              <LinkItem button>
                <ListItemIcon style={{ minWidth: "32px", marginLeft: "30px" }}>
                  {menu.icon}
                </ListItemIcon>
                <ListItemText primary={menu.label} />
              </LinkItem>
            </Link>
          ))}
        </MenuChildren>
        <Divider />
      </Fragment>
      )
    </>
  );
};

export default SidebarMenu;
