import React from "react";
import styled from "styled-components";

const BrandStyle = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 200px;

  .anima {
    font-size: 32px;
    font-style: normal;
    font-weight: bolder;
    font-family: "Poppins";
    line-height: 20px;
    letter-spacing: 0em;
    color: #1781ff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    white-space: nowrap;
  }
`;

const BrandComponent = () => {
  return (
    <BrandStyle>
      <img
        src="/MotionPixelsLogoHorizontal.png"
        width="100%"
        height="auto"
        alt="React Bootstrap logo"
      />
    </BrandStyle>
  );
};

export default BrandComponent;
