import React, { useEffect } from "react";
import { Form, withFormik } from "formik";
import * as yup from "yup";

import styled from "styled-components";

import {
  Button,
  Wrapper,
  Typography,
  InputField,
  FieldErrorMsg
} from "../../components";

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TagForm = ({
  values,
  errors,
  touched,
  handleChange,
  dirty,
  loading,
  resetForm,
  done
}) => {
  useEffect(() => {
    if (done) {
      resetForm();
    }
  }, [done, resetForm]);

  return (
    <Wrapper>
      <Form>
        <InputWrapper>
          <div style={{ flexBasis: "80%" }}>
            <Typography mb={2}>Name</Typography>
            <InputField
              fullWidth
              variant="outlined"
              size="small"
              name="name"
              multiline
              rows={4}
              value={values.name}
              onChange={handleChange}
            />
            <FieldErrorMsg error={errors.name} isTouched={touched.name} />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            loading={loading}
            disabled={!dirty || loading}
            style={{ display: "flex", alignItems: "flex-start" }}
          >
            Save
          </Button>
        </InputWrapper>
      </Form>
    </Wrapper>
  );
};

const trimValue = (arr) => {
  const filteArry = arr.filter((val) => val !== "");
  return filteArry.map((val) => val.trim());
};

const CreateTag = withFormik({
  mapPropsToValues: () => ({
    name: ""
  }),
  validationSchema: yup.object().shape({
    name: yup
      .string()
      .required("you must supply tag name")
      .min(3, "Tag name should contain at least 3 characters")
  }),
  handleSubmit(values, { props: { handleCreateTag } }) {
    // console.log(values);
    // console.log(trimValue(values.name.replace("\n", "").split(",")));
    // console.log(values);
    values.name = trimValue(values.name.replace("\n", "").split(","));
    // console.log(values);
    handleCreateTag(values);
  }
})(TagForm);

export default CreateTag;
