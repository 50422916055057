import { TablePagination } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

export default function PatchedPagination(props) {
  // console.log(props);
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    count,
    page,
    rowsPerPage,
    ...tablePaginationProps
  } = props;
  // console.log("count", count);
  const totalPage = Math.ceil(Number(count) / Number(rowsPerPage));

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      // page={page === 0 ? 1 : page === 1 ? 1 : page - 1}
      page={count <= 0 ? 0 : page + 1}
      count={count}
      rowsPerPage={rowsPerPage}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        // console.log(actionsComponentProps);
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <Pagination
            {...actionsComponentProps}
            count={totalPage}
            onChange={onPageChange}
            boundaryCount={1}
          />
        );
      }}
      // rowsPerPageOptions={[25, 50, 100]}
    />
  );
}
