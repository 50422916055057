import { useContext } from "react";
import { Card, CardContent } from "@material-ui/core";
import styled from "styled-components";
import { GridContainer, GridItem, Typography, Wrapper } from "../../components";
import AuthContext from "../../../context/AuthContext";
// import { API } from "../../../config"

const CardStyles = styled(Card)`
  background-color: ${({ bg }) => bg};
  box-shadow: 5px 0px 11px 6px rgba(205, 205, 205, 0.14);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CardTypography = styled(Typography)`
  padding-top: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: ${({ textcolor }) => textcolor};
`;

const CardImage = styled.img`
  width: 30%;
  height: auto;
  margin-left: 35%;
`;

const LoginCard = ({ redirect }) => {
  const { startSocialLogin } = useContext(AuthContext);

  const loginWithGoogleHandler = () => {
    startSocialLogin(redirect);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <Wrapper
            padding="20px 10px"
            height="80%"
            width="100%"
            onClick={loginWithGoogleHandler}
          >
            <CardStyles bg="#FFFFFF">
              <CardContent>
                <CardImage src="/Google.svg" alt="google-logo" />
                <CardTypography textcolor=" #4A4A4A">
                  Continue with google
                </CardTypography>
              </CardContent>
            </CardStyles>
          </Wrapper>
        </GridItem>
        <GridItem xs={12} md={6}>
          <Wrapper padding="20px 10px" height="80%" width="100%">
            <CardStyles bg="#4A7AFF">
              <CardContent>
                <CardImage src="/Facebook.svg" alt="facebook-logo" />
                <CardTypography textcolor="#FFFFFF">
                  Continue with Facebook
                </CardTypography>
              </CardContent>
            </CardStyles>
          </Wrapper>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <Wrapper padding="20px 10px" height="80%" width="100%">
            <CardStyles bg="#FE65AC">
              <CardContent>
                <CardImage src="/Dribble.svg" alt="dribble-logo" />
                <CardTypography textcolor="#FFFFFF">
                  Continue with Dribble
                </CardTypography>
              </CardContent>
            </CardStyles>
          </Wrapper>
        </GridItem>

        <GridItem xs={12} md={6}>
          <Wrapper padding="20px 10px" height="80%" width="100%">
            <CardStyles bg="#0053BF">
              <CardContent>
                <CardImage src="/Behance.svg" alt="behance-logo" />
                <CardTypography textcolor="#FFFFFF">
                  Continue with Behance
                </CardTypography>
              </CardContent>
            </CardStyles>
          </Wrapper>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default LoginCard;
