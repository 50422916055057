import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import { spacing, palette, sizing } from "@material-ui/system";

const PaperExtended = styled(Paper)`
  ${spacing}
  ${palette}
  ${sizing};
`;

export default PaperExtended;
