import * as types from "./types";

export const createTag = (body) => ({
  type: types.CREATE_TAG,
  meta: {
    body,
    async: true,
    blocking: true,
    path: `/tags`,
    method: "POST",
    isPrivate: true
  }
});

export const deleteTag = (id) => ({
  type: types.DELETE_TAG,
  meta: {
    async: true,
    blocking: true,
    path: `/tags/${id}`,
    method: "DELETE",
    isPrivate: true
  }
});

export const getTags = (params) => ({
  type: types.GET_TAGS,
  meta: {
    params,
    async: true,
    blocking: true,
    path: `/tags`,
    method: "GET"
    // isPrivate: true
  }
});

export const getTagsCount = () => ({
  type: types.GET_TAG_COUNT,
  meta: {
    async: true,
    blocking: true,
    path: `/tags/count`,
    method: "GET"
    // isPrivate: true
  }
});

export const resetTagState = () => ({
  type: types.RESET_TAG_STATE
});

export const resetGetTagsState = () => ({
  type: types.RESET_GET_TAGS_STATE
});
