import React from "react";
import Select from "react-select";
import Wrapper from "./Wrapper";
import styled from "styled-components";

const SelectStyle = styled(Select)`
  width: 100%;
`;

const customStyles = {
  control: (styles) => ({
    ...styles,
    borderStyle: "onne",
    borderBottom: "1px solid #aaa",
    borderRadius: 0,
    "&:hover": {
      borderBottom: "2px solid #000"
    }
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "9999"
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: 0
  })
};

const SelectExtended = ({
  name,
  value,
  options = [],
  onChange,
  isMulti,
  defaultValue,
  placeholder,
  isDisabled = false,
  width = "100%",
  ...rest
}) => {
  const handleChange = (selectedData) => {
    onChange(name, selectedData.value);
  };
  return (
    <Wrapper {...rest} width={width}>
      <SelectStyle
        styles={customStyles}
        name={name}
        value={value}
        isDisabled={isDisabled}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
        isMulti={isMulti}
      />
    </Wrapper>
  );
};
export default SelectExtended;
