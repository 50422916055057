import styled from "styled-components";
import { mediaUp } from "../styles/mediaQuery";

const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 25px;

  ${mediaUp.md`
    padding-right: 50px;
    padding-left: 50px;
  `}
`;

export default Container;
