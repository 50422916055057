import React from "react"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"

const StyledGridItem = styled(Grid)`
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
	flex-basis: auto;
`

export default function GridItem({ children, ...rest }) {
	return (
		<StyledGridItem item {...rest}>
			{children}
		</StyledGridItem>
	)
}
