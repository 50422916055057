import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { MainLayout } from "./views/layouts";
import { Message } from "./views/components";
import { AuthProvider } from "./context/AuthContext";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <AuthProvider>
          <Message />
          <MainLayout>
            <Routes />
          </MainLayout>
        </AuthProvider>
      </BrowserRouter>
    );
  }
}

export default App;
