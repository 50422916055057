import React, { useContext } from "react";
// import { Modal, Container, Row, Col, Button } from "react-bootstrap"
import { Container, Wrapper } from "../../components";
import { Grid } from "@material-ui/core";

import styled from "styled-components";
import { mediaDown } from "../../styles/mediaQuery";
import LoginForm from "./LoginForm";
import LoginCard from "./LoginCard";
import AuthContext from "../../../context/AuthContext";
import { Redirect } from "react-router-dom";

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 550px;

  img {
    width: 50%;
    min-width: 34px;
    height: auto;
  }
`;

// const LogTitle = styled.p`
//   font-size: 42px;
//   font-style: normal;
//   font-weight: bolder;
//   font-family: "Poppins";
//   line-height: 63px;
//   letter-spacing: 0em;
//   color: #1781ff;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 0;
// `;

const LoginWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
  }
  ${mediaDown.md`
    width: 100%;
     form {
       width: 90%
     }
  `}
  ${mediaDown.sm`
     form {
       width: 100%
     }
  `}
`;

const LinkGridItem = styled(Grid)`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex-basis: auto;
  ${mediaDown.md`
     max-width: 470px;
     display: flex;
     justify-content: center;
  `}
  ${mediaDown.sm`
     display: block;
  `}
`;

const LinkGridContainer = styled(Grid)`
  margin-right: -15px;
  margin-left: -15px;
  width: auto;
  ${mediaDown.md`
     display: flex;
     justify-content: center;
  `}
`;

const LoginPage = ({ btn, path, ...props }) => {
  const { user, loginUser } = useContext(AuthContext);

  const handleLogin = async (email) => {
    await loginUser(email, "/");
  };

  return (
    <>
      {user?.email && (
        <Redirect
          to={
            props?.location?.state?.wPath
              ? props?.location?.state?.wPath
              : "/dashboard"
          }
        />
      )}
      <Wrapper
      // style={{
      //   marginTop: "-80px",
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center"
      // }}
      >
        <Container>
          <Wrapper
            display="flex"
            justifyContent="center"
            style={{ maxHeight: "110px", marginBottom: "100px" }}
          >
            <ImageWrapper>
              <img src="/MotionPixelsLogoVertical.png" alt="anima" />
            </ImageWrapper>
          </Wrapper>

          {/* modal head end*/}

          <LinkGridContainer container>
            <LinkGridItem item xs={12} md={6}>
              <LoginCard redirect="dashboard" />
            </LinkGridItem>

            <LinkGridItem item md={1}>
              <div>
                <span></span>
                <p>or</p>
                <span></span>
              </div>
            </LinkGridItem>

            <LinkGridItem item xs={12} md={5}>
              <LoginWrapper>
                <LoginForm handleLogin={handleLogin} />
              </LoginWrapper>
            </LinkGridItem>
          </LinkGridContainer>
        </Container>
      </Wrapper>
    </>
  );
};

export default LoginPage;
