import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Wrapper, Button } from "../../components";
import { documentActions } from "../../../state/document";
import { productActions } from "../../../state/product";
import { notificationActions } from "../../../state/notification";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import ImageUploadForm from "./uploadForm";
import "./index.css";

const fontSize = 24;
const Spin = (
  <i className="fas fa-sync-alt rc-loading-spin" style={{ fontSize }} />
);
const styles = { marginBottom: 20, height: 150, width: "100%" };

const ProductContainer = () => {
  const [fileInfo, setFileInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Processing");
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    upload,
    uploadToS3,
    resetUploader,
    uploadImage,
    uploadVideo,
    resetDocumentUpload
  } = documentActions;
  const { showNotification } = notificationActions;
  const { resetProductAddState, saveFileName, resetFileSaveState } =
    productActions;

  const {
    productDetails,
    getSignedURLDone,
    signedURLInfo,
    uploadDocumentDone,
    getSignedURLFailed,
    errorMessage,
    isUploadingImageDone,
    isUploadingVideoDone,
    uploadDocumentFailed,
    isSavingFileToStrapiDone,
    isSavingFileToStrapiFailed
  } = useSelector(({ product, document }) => ({
    productDetails: product.productData,
    isGettingSignedURL: document.isGettingSignedURL,
    getSignedURLDone: document.getSignedURLDone,
    signedURLInfo: document.signedURLInfo,
    uploadDocumentDone: document.uploadDocumentDone,
    uploadDocumentFailed: document.uploadDocumentFailed,
    getSignedURLFailed: document.getSignedURLFailed,
    errorMessage: document.errorMessage,
    isUploadingVideoDone: document.isUploadingVideoDone,
    isUploadingImageDone: document.isUploadingImageDone,
    isSavingFileToStrapiDone: product.isSavingFileToStrapiDone,
    isSavingFileToStrapiFailed: product.isSavingFileToStrapiFailed
  }));

  // console.log(productDetails);

  const handleFileInfo = (values) => {
    setFileInfo(values);
  };

  const handleUploadFile = async () => {
    const file = {
      title: productDetails.title,
      type: fileInfo.type
    };
    setLoading(true);
    dispatch(upload(file));
    setMessage("Getting Secure S3 URL");
  };

  useEffect(() => {
    return () => dispatch(resetProductAddState());
  }, [resetProductAddState, dispatch]);

  useEffect(() => {
    if (getSignedURLDone) {
      setMessage("uploading json to s3");
      dispatch(showNotification("uploading json to s3", "success"));
      dispatch(
        uploadToS3({
          file: fileInfo,
          fileType: fileInfo?.type,
          uploadURL: signedURLInfo.uploadURL
        })
      );
    }
    if (getSignedURLFailed) {
      setLoading(true);
      dispatch(showNotification("Failed to getting s3 signed URL", "error"));
      dispatch(resetUploader());
      dispatch(showNotification("Retry please", "error"));
    }
  }, [
    getSignedURLDone,
    dispatch,
    fileInfo,
    signedURLInfo,
    uploadToS3,
    showNotification,
    resetUploader,
    getSignedURLFailed,
    errorMessage
  ]);
  useEffect(() => {
    if (uploadDocumentDone) {
      const fileName = signedURLInfo?.fileName.split(".")[0];
      dispatch(
        saveFileName(productDetails?.id, {
          fileName
        })
      );
    }
    if (uploadDocumentFailed) {
      dispatch(showNotification("Failed to upload Json to s3", "error"));
      setLoading(false);
      dispatch(resetDocumentUpload());
      dispatch(showNotification("Retry on again", "error"));
    }
  }, [
    dispatch,
    uploadDocumentDone,
    uploadDocumentFailed,
    setLoading,
    resetDocumentUpload,
    showNotification,
    productDetails,
    signedURLInfo,
    saveFileName
  ]);

  useEffect(() => {
    if (isSavingFileToStrapiDone) {
      dispatch(showNotification("creating image", "success"));
      setMessage("creating image and video");
      dispatch(
        uploadImage({ fileName: signedURLInfo.fileName, id: productDetails.id })
      );
      dispatch(
        uploadVideo({ fileName: signedURLInfo.fileName, id: productDetails.id })
      );
    }
    if (isSavingFileToStrapiFailed) {
      dispatch(
        showNotification("Failed to save fileName in strapi product", "error")
      );
      dispatch(resetFileSaveState());
      setLoading(false);
      dispatch(resetDocumentUpload());
      dispatch(showNotification("Retry on again", "error"));
    }
  }, [
    resetDocumentUpload,
    signedURLInfo,
    dispatch,
    uploadImage,
    uploadVideo,
    productDetails,
    showNotification,
    isSavingFileToStrapiDone,
    isSavingFileToStrapiFailed,
    resetFileSaveState
  ]);

  useEffect(() => {
    if (isUploadingImageDone) {
      setMessage("Creating video");
      dispatch(showNotification("image created successfully", "success"));
    }
    if (isUploadingVideoDone) {
      dispatch(showNotification("video created successfully", "success"));
      setLoading(false);
      dispatch(resetProductAddState());
      dispatch(resetUploader());
      dispatch(resetFileSaveState());
      history.push("/products/list");
      return () => setFileInfo("");
    }
  }, [
    dispatch,
    showNotification,
    isUploadingImageDone,
    isUploadingVideoDone,
    resetProductAddState,
    resetUploader,
    history,
    resetFileSaveState
  ]);

  return (
    <>
      {loading && (
        <LoadingMask
          className="customize"
          loading={loading}
          loadingText={`${message}...`}
          indicator={Spin}
        >
          <div style={styles} />
        </LoadingMask>
      )}

      <Wrapper
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
        width="100%"
        maxWidth="1100px"
        marginTop="40px"
        position="relative"
      >
        {loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background: "#00000555",
              zIndex: 111
            }}
          />
        )}
        <ImageUploadForm
          handleFileInfo={handleFileInfo}
          fileName={fileInfo?.name}
        />
        <Button color="primary" variant="contained" onClick={handleUploadFile}>
          Finish
        </Button>
      </Wrapper>
    </>
  );
};

export default ProductContainer;
