import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    right: 0,
    backgroundColor: "#fff",
    minWidth: 250,
    padding: "8px 16px",
    borderRadius: 4,
    boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
    top: "calc(100% + 16px)"
  },
  checkboxLabel: {
    display: "flex"
  }
});

const ColumnToggle = ({ className, hideColumn, columns }) => {
  const classes = useStyles();

  const handleChange = (col) => (event) => {
    // if (col?.hidden) {
    // col.hidden = false;
    hideColumn(col, !event.target.checked);
    // console.log(col);
    // localStorage.setItem("Columns", JSON.stringify(columns));
    // } else {
    //   hideColumn(col, !event.target.checked);
    //   // console.log(col);
    localStorage.setItem("Columns", JSON.stringify(columns));
    // }
  };

  return (
    <div className={clsx(classes.root, className && className)}>
      {columns.map((col) => (
        <FormControlLabel
          key={col.field}
          labelPlacement="start"
          label={col.title}
          classes={{ root: classes.checkboxLabel }}
          control={
            <Checkbox
              checked={!col.hidden}
              onChange={handleChange(col)}
              value={!col.hidden}
              color="primary"
            />
          }
        />
      ))}
    </div>
  );
};
export default ColumnToggle;
