import * as types from "./types";

const initialState = {
  tag: {},
  tags: [],
  count: 0
};

const tagReducers = function (state = initialState, { type, payload }) {
  switch (type) {
    case types.CREATE_TAG:
      return {
        ...state,
        tag: {},
        isCreatingTag: true,
        isCreatingTagDone: false,
        isCreatingTagFailed: false
      };
    case types.CREATE_TAG_DONE:
      return {
        ...state,
        isCreatingTag: false,
        isCreatingTagDone: true,
        isCreatingTagFailed: false,
        tag: payload
      };
    case types.CREATE_TAG_FAILED:
      return {
        ...state,
        tag: {},
        isCreatingTag: false,
        isCreatingTagDone: false,
        isCreatingTagFailed: true
      };
    case types.RESET_TAG_STATE:
      return {
        ...state,
        isCreatingTag: false,
        isCreatingTagDone: false,
        isCreatingTagFailed: false
      };

    case types.GET_TAG_COUNT:
      return {
        ...state,
        count: 0,
        isGettingTagCount: true,
        isGettingTagCount_failed: false,
        isGettingTagCount_done: false
      };
    case types.GET_TAG_COUNT_DONE:
      return {
        ...state,
        count: payload,
        isGettingTagCount: false,
        isGettingTagCount_failed: false,
        isGettingTagCount_done: true
      };
    case types.GET_TAG_COUNT_FAILED:
      return {
        ...state,
        count: 0,
        isGettingTagCount: false,
        isGettingTagCount_failed: true,
        isGettingTagCount_done: false
      };

    case types.GET_TAGS:
      return {
        ...state,
        tags: [],
        isGettingTags: true,
        isGettingTagsDone: false,
        isGettingTagsFailed: false
      };
    case types.GET_TAGS_DONE:
      return {
        ...state,
        isGettingTags: false,
        isGettingTagsDone: true,
        isGettingTagsFailed: false,
        tags: payload
      };
    case types.GET_TAGS_FAILED:
      return {
        ...state,
        tags: [],
        isGettingTags: false,
        isGettingTagsDone: false,
        isGettingTagsFailed: true
      };
    case types.DELETE_TAG:
      return {
        ...state,
        // productDetails: {},
        isDeletingTag: true,
        isDeletingTagFailed: false,
        isDeletingTagDone: false
      };
    case types.DELETE_TAG_DONE:
      return {
        ...state,
        // productDetails: payload,
        isDeletingTag: false,
        isDeletingTagFailed: false,
        isDeletingTagDone: true
      };

    case types.DELETE_TAG_FAILED:
      return {
        ...state,
        // productDetails: {},
        isDeletingTag: false,
        isDeletingTagFailed: true,
        isDeletingTagDone: false
      };

    case types.RESET_GET_TAGS_STATE:
      return {
        ...state,
        isGettingTags: false,
        isGettingTagsDone: false,
        isGettingTagsFailed: false
      };
    default:
      return state;
  }
};

export default tagReducers;
