export const LOGIN = "auth/login";
export const LOGIN_DONE = "auth/login_done";
export const LOGIN_FAILED = "auth/login_failed";

export const LOGOUT = "auth/logout";

export const SET_LOGGED_IN_USER = "auth/set_logged_in_user";
export const SET_USER_LOGGED_IN = "auth/set_user_logged_in";
export const SET_USER_UNAUTHORIZED = "auth/set_user_unauthorized";

export const CHECK_STATUS = "auth/check_status";
export const CHECK_STATUS_DONE = "auth/check_status_done";
export const CHECK_STATUS_FAILED = "auth/check_status_failed";
