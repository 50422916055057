import React from "react";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";

const AvatarWrapper = styled(Avatar)`
  && {
    width: ${(props) => props.size + "px"};
    height: ${(props) => props.size + "px"};
  }
`;

const AvatarExtended = ({ src, name = "", size = 60, ...rest }) => (
  <AvatarWrapper src={src} size={size} alt={name} {...rest}>
    {name ? name : ""}
  </AvatarWrapper>
);

export default AvatarExtended;
