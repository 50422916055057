import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Wrapper } from "./";

const DialogHeader = ({ children, onClose, ...rest }) => {
  return (
    <DialogTitle
      disableTypography
      {...rest}
      style={{
        backgroundColor: "#FAFAFA",
        height: "60px"
      }}
    >
      <Grid container>
        <Grid item xs={11}>
          <Typography
            variant="h6"
            style={{
              textAlign: "center"
            }}
          >
            {children}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Wrapper textAlign="right">
            {onClose && (
              <IconButton
                aria-label="close"
                onClick={onClose}
                style={{
                  marginTop: "-12px"
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Wrapper>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default function DialogExtended({
  label = "untitled-dialog",
  open,
  heading,
  handleClose,
  children,
  maxWidth = "md",
  Content,
  ActionsContent
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={label}
      open={open}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <DialogHeader id="customized-dialog-title" onClose={handleClose}>
        {heading}
      </DialogHeader>
      <DialogContent style={{ minHeight: "110px" }}>
        <Content />
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#EFF3F6", height: "60px" }}>
        {ActionsContent && <ActionsContent />}
      </DialogActions>
    </Dialog>
  );
}
