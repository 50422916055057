import moment from "moment";

export const columns = [
  {
    title: "Name",
    field: "name",
    render: ({ name }) => <div>{name.toLowerCase()}</div>
  },
  {
    title: "Products",
    field: "products",
    render: ({ products }) => <div>{`${products?.length} items`}</div>
  },

  {
    title: "published_at",
    field: "published_at",
    render: ({ published_at }) => (
      <div>{moment(published_at).format("do MMM yy")}</div>
    )
  },
  {
    title: "created_at",
    field: "created_at",
    render: ({ created_at }) => (
      <div>{moment(created_at).format("do MMM yy")}</div>
    )
  },
  {
    title: "updated_at",
    field: "updated_at",

    render: ({ updated_at }) => (
      <div>{moment(updated_at).format("do MMM yy")}</div>
    )
  }
];
