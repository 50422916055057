import React from "react";
import moment from "moment";
import { Wrapper, Paper, Typography } from "../../components";
import styled from "styled-components";

const Layout = styled(Paper)`
  width: 100%;
  padding: 15px;
`;

const Information = ({ updated }) => {
  return (
    <Layout>
      <Typography mt={2}>Information</Typography>
      <Wrapper mt={2} display="flex" justifyContent="space-between">
        <Typography
          style={{
            textTransform: "Uppercase",
            fontSize: "13px",
            color: "#9ea7bc"
          }}
        >
          Last update
        </Typography>
        <Typography
          style={{
            fontSize: "14px"
          }}
        >
          {moment(updated).fromNow()}
        </Typography>
      </Wrapper>
      <Wrapper mt={3}>
        <Typography
          style={{
            textTransform: "Uppercase",
            fontSize: "13px",
            color: "#9ea7bc"
          }}
        >
          BY
        </Typography>
      </Wrapper>
    </Layout>
  );
};

export default Information;
