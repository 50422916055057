import React from "react";
import withSVG from "./withSVG";

const AllCategoriesIcon = () => (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.174 12c0-4.853 3.862-8.8 8.608-8.8 4.747 0 8.609 3.947 8.609 8.8 0 4.854-3.862 8.8-8.609 8.8-4.746 0-8.608-3.946-8.608-8.8zm16.488 8.115h-1.084a11.633 11.633 0 003.727-8.565C29.305 5.181 24.233 0 18 0 11.764 0 6.693 5.182 6.693 11.55c0 3.395 1.44 6.45 3.729 8.565H9.339c-5.152 0-9.34 4.28-9.34 9.538v8.852C0 39.33.655 40 1.463 40h13.126c.808 0 1.462-.67 1.462-1.495 0-.825-.654-1.494-1.462-1.494h-3.397v-2.53c0-.823-.654-1.493-1.46-1.493-.808 0-1.462.67-1.462 1.494v2.53H2.922v-7.359c0-3.61 2.878-6.55 6.417-6.55h17.323c3.539 0 6.415 2.94 6.415 6.55v7.358h-5.346v-2.53c0-.823-.655-1.493-1.46-1.493-.81 0-1.463.67-1.463 1.494v2.53H21.41c-.808 0-1.462.668-1.462 1.493 0 .825.654 1.495 1.462 1.495h13.128c.809 0 1.462-.67 1.462-1.495v-8.852c0-5.259-4.187-9.538-9.338-9.538z"
      fill="#B8B8B8"
    />
  </g>
);

export default withSVG(AllCategoriesIcon, {
  viewBox: "0 0 36 40",
  width: "30",
  height: "30",
  fill: "none"
});
