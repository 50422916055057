export const ADD_PRODUCT = "products/add_product";
export const ADD_PRODUCT_DONE = "products/add_product_done";
export const ADD_PRODUCT_FAILED = "products/add_product_failed";

export const GET_PRODUCT = "products/get_product";
export const GET_PRODUCT_DONE = "products/get_product_done";
export const GET_PRODUCT_FAILED = "products/get_product_failed";

export const GET_PRODUCTS = "products/get_products";
export const GET_PRODUCTS_DONE = "products/get_products_done";
export const GET_PRODUCTS_FAILED = "products/get_products_failed";

export const UPDATE_PRODUCT = "products/update_product";
export const UPDATE_PRODUCT_DONE = "products/update_product_done";
export const UPDATE_PRODUCT_FAILED = "products/update_product_failed";

export const DELETE_PRODUCT = "products/delete_product";
export const DELETE_PRODUCT_DONE = "products/delete_product_done";
export const DELETE_PRODUCT_FAILED = "products/delete_product_failed";

export const GET_PRODUCT_COUNT = "products/get_productCount";
export const GET_PRODUCT_COUNT_DONE = "products/get_productCount_done";
export const GET_PRODUCT_COUNT_FAILED = "products/get_productCount_failed";

export const RESET_PRODUCT_GET_STATE = "products/reset_product_get_state";

export const RESET_ADD_STATE = "products/reset_add_state";

export const SAVE_FILE_NAME_TO_STRAPI = "products/save-file_name_to_strapi";

export const SAVE_FILE_NAME_TO_STRAPI_DONE =
  "products/save-file_name_to_strapi_done";
export const SAVE_FILE_NAME_TO_STRAPI_FAILED =
  "products/save-file_name_to_strapi_failed";

export const RESET_FILE_SAVE_STATE = "products/reset_file_save_state";

export const RESET_UPDATE_STATE = "products/reset_update_state";

export const RESET_DELETE_STATE = "products/reset_update_state";
