import React from "react";
import styled from "styled-components";
import { color } from "@material-ui/system";
import { CircularProgress, Typography } from "@material-ui/core";

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 400)}px ${color};
`;

const PreLoader = ({
  isLoading,
  loadingMessage,
  color,
  minHeight,
  children
}) => (
  <>
    {isLoading ? (
      <LoaderContainer color={color} minHeight={minHeight}>
        <CircularProgress
          className={`seconadry-loader ${loadingMessage ? "with-message" : ""}`}
        ></CircularProgress>
        <Typography align="center" className="loading-message">
          {loadingMessage}
        </Typography>
      </LoaderContainer>
    ) : (
      children
    )}
  </>
);

export default PreLoader;
