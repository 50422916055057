import React from "react";
import { TextField } from "@material-ui/core";
import { spacing, typography } from "@material-ui/system";
import styled from "styled-components";

const InputField = styled(({ ...rest }) => <TextField {...rest} />)`
  .MuiOutlinedInput-root {
    background: #ffffff;
  }

  ${spacing}
  ${typography}
`;

export default InputField;
