import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { notificationActions } from "../../state/notification";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Message() {
  const dispatch = useDispatch();

  const { snackbarMessage, snackbarOpen, variant } = useSelector(
    (state) => state.notification
  );
  const { clearNotification } = notificationActions;

  function handleClose() {
    dispatch(clearNotification());
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={variant}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
}
