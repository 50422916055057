import React from "react";

import {
  Paper,
  Button,
  FullPageLoader,
  AsyncSelect,
  Typography,
  Wrapper
} from "../../components";
import { Form, withFormik } from "formik";
import axios from "axios";
import { api } from "../../../config";
import ProductFieldForm from "./ProductFieldForm";
import styled from "styled-components";
import Information from "./Information";
import * as yup from "yup";
import { getToken } from "../../../services/ApiService";
import fetch from "../../../utils/axios";

// import RelationalInfo from "./RelationalFields.js";

const ProductLayer = styled(Form)`
  display: flex;
  min-height: 100%;
`;

const ProductFields = styled(Paper)`
  flex-basis: 75%;
  background-color: #ffffff;
  margin-right: 2%;
  min-height: 100%;
`;

const RelationalFields = styled.div`
  flex-basis: 25%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
`;

const OrderLayout = styled(Paper)`
  margin-top: 30px;
  width: 100%;
  padding: 15px;
`;

const FormLayout = ({
  values,
  errors,
  touched,
  handleChange,
  loading,
  isPageLoading,
  dirty,
  updated,
  setFieldValue,
  showTagForm
}) => {
  const trimValue = (arr) => {
    const filteArry = arr.filter((val) => val !== "");
    return filteArry.map((val) => val.trim());
  };

  const loadOptions = async (value) => {
    // console.log(value);
    if (value.includes(",")) {
      const token = await getToken();

      const reqTags = trimValue(value.split(","));
      const res = await fetch({
        url: `${api}/tags/queryTags`,
        method: "Post",
        token: `Bearer ${token}`,
        body: { tags: reqTags }
      });
      // console.log(res);
      if (res.length !== 0) {
        const newTags = [
          ...new Set([
            ...values.tags,
            ...res.map((tag) => ({
              value: tag.id,
              label: tag.name.toLowerCase()
            }))
          ])
        ];
        setFieldValue("tags", newTags);
      }

      return;
    } else {
      const res = await axios.get(`${api}/tags?_q=${value}`);
      const tags = res.data.map((tag) => ({
        value: tag.id,
        label: tag.name.toLowerCase()
      }));

      return tags;
    }
  };
  return (
    <ProductLayer>
      <FullPageLoader isLoading={isPageLoading} />
      <ProductFields>
        <Container>
          <ProductFieldForm
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            loading={loading}
            isPageLoading={isPageLoading}
            dirty={dirty}
          />
        </Container>
      </ProductFields>
      <RelationalFields>
        <Information updated={updated} />
        <OrderLayout>
          <Wrapper display="flex" justifyContent="space-between">
            <Typography mt={2} mb={2}>
              Tags ({values?.tags?.length})
            </Typography>
            <Typography
              style={{
                alignSelf: "center",
                fontSize: 24,
                color: "#1781ff",
                cursor: "pointer"
              }}
              onClick={showTagForm}
            >
              +
            </Typography>
          </Wrapper>
          <AsyncSelect
            id="tags"
            name="tags"
            isMulti
            errors={errors}
            touched={touched}
            defaultOptions
            onChange={setFieldValue}
            value={values.tags}
            loadOptions={loadOptions}
          />
        </OrderLayout>
        {/* <RelationalInfo orders={location?.state?.rowData?.orders} /> */}
      </RelationalFields>
      <Button
        type="submit"
        disabled={!dirty}
        variant="contained"
        color="primary"
        size="small"
        style={{
          position: "absolute",
          top: 10,
          right: "25%",
          textTransform: "Capitalize"
        }}
      >
        save
      </Button>
    </ProductLayer>
  );
};

const EditForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ product = {} }) => ({
    author: product.author || "",
    title: product.title || "",
    price: product.price || "",
    description: product.description || "",
    image: product.image || "",
    video: product.video || "",
    tags:
      (product.tags &&
        product.tags.map((tag) => ({
          label: tag.name.toLowerCase(),
          value: tag.id
        }))) ||
      []
  }),
  validationSchema: yup.object().shape({
    title: yup.string().required("please select type"),
    price: yup.number().required("price is required"),
    description: yup
      .string()
      .min(10, "Enter at least 10 character")
      .max(2000, "Long description is too long (maximum is 2000 characters).")
      .required("please enter long description")
  }),
  handleSubmit(values, { props: { updateHandler }, resetForm }) {
    const payload = {
      ...values,
      tags: values?.tags?.length ? values?.tags?.map((t) => t.value) : []
    };
    updateHandler(payload);
  }
})(FormLayout);

export default EditForm;
