import React from "react";
import { Wrapper, Button } from "../../components";

const DialogActions = ({ handleClose, deleteHandler }) => {
  return (
    <Wrapper
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{ width: "80%", margin: "auto" }}
    >
      <Button
        size="small"
        variant="outlined"
        onClick={handleClose}
        style={{
          borderRadius: "4px",
          textTransform: "Capitalize"
        }}
      >
        No, Cancel
      </Button>
      <Button
        size="small"
        variant="contained"
        onClick={deleteHandler}
        style={{
          backgroundColor: "#F64D0A",
          borderRadius: "4px",
          textTransform: "Capitalize"
        }}
      >
        Yes, Confirm
      </Button>
    </Wrapper>
  );
};

export default DialogActions;
