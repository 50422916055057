import styled from "styled-components";
import {
  palette,
  spacing,
  sizing,
  borders,
  display,
  flexbox,
  typography,
  positions
} from "@material-ui/system";

const Wrapper = styled.div`
  ${palette};
  ${spacing};
  ${sizing};
  ${borders};
  ${display};
  ${flexbox};
  ${positions};
  ${typography};
`;

export default Wrapper;
