import React, { useState } from "react";

import CreateProduct from "./createProduct";
import Upload from "./upload";

const ProductCreateContainer = () => {
  const [step, setStep] = useState("1");

  return (
    <>
      {step === "1" && <CreateProduct setStep={setStep} />}
      {step === "2" && <Upload />}
    </>
  );
};

export default ProductCreateContainer;
