import * as types from "./types";

export const showNotification = (message, variant) => ({
  type: types.SNACKBAR_OPEN,
  meta: {
    message,
    variant,
    async: false,
    blocking: false
  }
});

export const clearNotification = () => ({
  type: types.SNACKBAR_CLEAR,
  meta: {
    async: false,
    blocking: false
  }
});
