import React from "react";
import { Grid, Typography } from "@material-ui/core";

const ForOFourPage = () => (
  <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    style={{ minHeight: "90vh" }}
  >
    <Grid item xs={12}>
      <Typography variant="h4" align="center">
        404 | Not found
      </Typography>
    </Grid>
  </Grid>
);

export default ForOFourPage;
