import React from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import { spacing, typography, palette } from "@material-ui/system";
import { fonts } from "../styles/theme";

const TypographyExtended = styled(
  ({ fontColor, fontFamily, transform, lineHeight, ...rest }) => (
    <Typography {...rest} />
  )
)`
  text-transform: ${({ transform }) => (transform ? transform : "inherit")};

  ${({ fontColor }) =>
    fontColor === "dark"
      ? css`
          color: #000000;
        `
      : fontColor === "light"
      ? css`
          color: #ffffff;
        `
      : ""};

  ${({ fontFamily }) =>
    fontFamily === "titleFont"
      ? css`
          font-family: ${fonts.titleFont};
        `
      : fontFamily === "bodyFont"
      ? css`
          font-family: ${fonts.bodyFont};
        `
      : css`
          font-family: inherit;
        `};

  em {
    color: ${({ theme }) => theme.palette.primary.main};
    font-style: normal;
  }

  ${spacing}
  ${palette}
  ${typography}
`;

export default TypographyExtended;
