import * as types from "./types";

export const upload = (data) => ({
  type: types.GET_S3_URL,
  meta: {
    async: true,
    blocking: true,
    path: `/get-url`,
    method: "POST",
    body: data
  }
});

export const uploadToS3 = (data) => ({
  type: types.UPLOAD_DOCUMENT,
  meta: {
    async: true,
    blocking: true,
    method: "PUT",
    path: "/",
    body: data,
    type: "S3Upload"
  }
});

export const uploadImage = (body) => ({
  type: types.IMAGE_UPLOAD,
  meta: {
    body,
    async: true,
    blocking: true,
    path: "/image",
    method: "POST"
  }
});

export const uploadVideo = (body) => ({
  type: types.VIDEO_UPLOAD,
  meta: {
    body,
    blocking: true,
    async: true,
    path: "/video",
    method: "POST"
  }
});
export const uploadGif = (body) => ({
  type: types.GIF_UPLOAD,
  meta: {
    body,
    async: true,
    path: "/gif",
    method: "POST"
  }
});

export const resetUploadError = () => ({
  type: types.RESET_UPLOAD_ERROR
});

export const resetUploader = () => ({
  type: types.RESET_UPLOADER
});

export const resetDocumentUpload = ()=> ({
  type: types.DOCUMENT_UPLOAD_RESET
})