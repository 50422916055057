import React from "react";
import AsyncSelect from "react-select/async";
// import { components } from "react-select";
// import chroma from "chroma-js";

const Select = ({
  name,
  value,
  onChange,
  isMulti,
  defaultValue,
  placeholder,
  isDisabled = false,
  width = "100%",
  ...props
}) => {
  // const colourStyles = {
  //   control: (styles, state) => ({
  //     ...styles,
  //     border: state.isFocused
  //       ? "1px solid rgba(0,0,0,0.23)"
  //       : "1px solid #e7e7e7",
  //     boxShadow: "0 !important",
  //     "&:hover": {
  //       borderColor: state.isFocused && "rgba(0,0,0,0.23)"
  //     }
  //   }),
  //   option: (styles, { isDisabled, isFocused, isSelected }) => {
  //     const color = chroma("rgb(255, 227, 63)");
  //     return {
  //       ...styles,
  //       backgroundColor: isDisabled
  //         ? null
  //         : isSelected
  //         ? "rgb(255, 227, 63)"
  //         : isFocused
  //         ? "var(--primary-color)"
  //         : null,
  //       color: isDisabled
  //         ? "#ccc"
  //         : isSelected
  //         ? chroma.contrast(color, "white") > 2
  //           ? "white"
  //           : "#4a4a4a"
  //         : "#4a4a4a",
  //       cursor: isDisabled ? "not-allowed" : "default",

  //       ":active": {
  //         ...styles[":active"],
  //         backgroundColor: !isDisabled && isSelected && "rgb(255, 227, 63)"
  //       }
  //     };
  //   },
  //   menu: (styles) => ({
  //     ...styles,
  //     zIndex: "999"
  //   }),
  //   input: (styles) => ({ ...styles }),
  //   placeholder: (styles) => ({ ...styles }),
  //   singleValue: (styles) => ({ ...styles })
  // };

  const handleChange = (selectedData) => {
    onChange(name, selectedData);
  };

  // const ValueContainer = ({ children, getValue, ...props }) => {
  //   let maxToShow = 3;
  //   var length = getValue().length;
  //   let displayChips = React.Children.toArray(children).slice(0, maxToShow);
  //   let shouldBadgeShow = length > maxToShow;
  //   let displayLength = length - maxToShow;

  //   return (
  //     <components.ValueContainer {...props}>
  //       {!props.selectProps.inputValue && displayChips}
  //       <div className="root">
  //         {shouldBadgeShow &&
  //           `+ ${displayLength} item${length != 1 ? "s" : ""} selected`}
  //       </div>
  //     </components.ValueContainer>
  //   );
  // };

  return (
    <AsyncSelect
      // styles={colourStyles}

      name={name}
      value={value}
      isDisabled={isDisabled}
      placeholder={placeholder}
      onChange={handleChange}
      isMulti={isMulti}
      defaultOptions
      cacheOptions
      components={{
        // ValueContainer,
        IndicatorSeparator: () => null
      }}
      {...props}
    />
  );
};

export default Select;
